
    <button mat-icon-button class="icon-favorite favorite-icon"
        aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="icon-favorite favorite-icon"
        aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button data-bs-toggle="tooltip" title="Ocorrências" class="icon-ocurrencies"
        aria-label="Ícone de ocorrências">
        <mat-icon>error</mat-icon>
    </button>
    
    
