import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationToastService } from 'src/app/notification-toast.service';
import { LoginServiceService } from '../login-service.service';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {

  constructor(
    private loginService: LoginServiceService, 
    private router: Router,
    private toast : NotificationToastService
    ) { }

  loading : boolean = false;

  loggedIn = false;
  value = '';
  hide = true;

  token : string = '';
  tokenLog: string = '';

  sessionStorage : any[] = [];
  ngOnInit(): void {
  }

  logOn(login : string, password : string){
      
    // console.info('Login: ', login );
    // console.info('Senha: ', password);
    this.loading = true;
    this.loginService.auth(login, password)
    .subscribe(data => {
      let result = data as any;
      
      if(result.status){
        
        console.log('inválido')
        this.toast.showError("Verifique se seu login e sua senha estão corretos.", "Credenciais Inválidas")
      } else {
        
        
        this.token = btoa(result.token);
    
        let loginLog = login;
        let passwordLog = password;
        login = btoa(login)
        password = btoa(password)
        this.loginService.authLog(loginLog, passwordLog).subscribe(data => {
          
          let result = data as any;
          if(result.status){
            this.toast.showError("Verifique se seu login e sua senha estão corretos..", "Credenciais Inválidas")
            this.loading = false;
          } else {
            this.tokenLog = btoa(result.token);
            sessionStorage.setItem("session", JSON.stringify({token: this.token, tokenLog: this.tokenLog, loggedIn: true, l: login, p: password}))
        
            this.toast.showSuccess("Seja Bem-Vindo", "Sempre IoT")
          
            this.router.navigate(['/home'], {queryParams: {login: login}});
            
            this.loggedIn = true;
            this.loading = false;
          }
          
        }, err => {this.loading = false;});
        
      }      
      
      this.loading = false;
    });
    
  }
}
