import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceService } from 'src/app/api/device.service';
import { NotificationToastService } from 'src/app/notification-toast.service';
import { DeviceAddModalComponent } from '../device-add-modal/device-add-modal.component';

@Component({
  selector: 'app-device-remove-modal',
  templateUrl: './device-remove-modal.component.html',
  styleUrls: ['./device-remove-modal.component.scss']
})
export class DeviceRemoveModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public dialogRef: MatDialogRef<DeviceAddModalComponent>,
    private _deviceService: DeviceService,
    private _toast: NotificationToastService) { }


  _isLoading : boolean = false;  
  ngOnInit(): void {
  }

  removeDeviceApi() {
    this._isLoading = true;
    this._deviceService.removeDevice(this.dataDialog.chipId).subscribe(data => {
      console.log(data);
      let result = data as any;
      if (result.codigo == "0") {
        this._toast.showInfo(result.mensagem, this.dataDialog.description)
      } else {
        this._toast.showInfo("Ops..", "Ocorreu algum erro ao tentar remover o dispositivo, tente novamente.")
      }
      
      setTimeout(() => {
        this.closeAndRefresh();
        this._isLoading = false;
      },
        1000);
      
    })



  }

  closeAndRefresh() {
    this.dialogRef.close();
    window.location.reload();
  }

}
