<p>{{data.contract}}</p>
<p>{{data.contractDescription}}</p>

<div *ngIf="userAcess?.length == 0">
    Esse contrato não possui acessos.
</div>


<div *ngIf="_isLoading">
    <mat-spinner diameter="40" style="margin:6% auto;"></mat-spinner>
</div>

<mat-divider style="margin-top: 3%;"></mat-divider>
<p><b>Conceder acesso</b></p>
<div class="container d-flex justify-content-center" style="margin-top: 5%;">
    <mat-form-field class="w-75 p-3" appearance="fill">
        <mat-label>Login sem pontos e traços.</mat-label>
        <input matInput maxlength="14" type="text" [formControl]="loginFormControl">
        <button *ngIf="loginFormControl.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="loginFormControl.setValue('')">
            <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="loginFormControl.hasError('required')">
            <p>O LOGIN é obrigatório ser preenchido.</p>
        </mat-error>
        <mat-hint>{{loginFormControl.value?.length || 0}}/14</mat-hint>
    </mat-form-field>

        <button mat-button [matMenuTriggerFor]="menu">{{permissionText}}<mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button (click)="setRead()" mat-menu-item>Leitura
            <mat-icon >visibility</mat-icon>
          </button>
          <button (click)=setWrite() mat-menu-item>Escrita
            <mat-icon >visibility</mat-icon><mat-icon >edit</mat-icon>
          </button>
        </mat-menu>
      
</div>

<div style="text-align: center;" *ngIf="loginFormControl.value?.length >= 9 && permission != ''">
    <button class="btn btn-outline-success" (click)="shareContract(loginFormControl.value)" >Compartilhar !</button>
</div>

<mat-divider style="margin-top: 3%;"></mat-divider>
<p><b>Remover acesso</b></p>
<mat-selection-list *ngIf="!_isLoading" #user [multiple]="false">
    <mat-list-option *ngFor="let user of userAcess" [value]="user">
        <div class="row">
            <div class="col col-sm-1" style="text-align: end; vertical-align: middle; ">
                <button mat-icon-button>
                    <mat-icon style="color: red;" [matTooltip]="getRemoveButtonTooltip(user.nome)" (click)="unshareContract(user.login)">close</mat-icon>
                </button>
            </div>
            <div class="col col-lg-10" style="text-align: start; margin-top: 1%;">
                <div style="vertical-align: middle;">{{user.nome}}</div>

            </div>

        </div>

    </mat-list-option>
</mat-selection-list>


