import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationToastService {

  constructor(private toastr: ToastrService) {
   }
  
  showInfoDevice(message: any, title: any){
    this.toastr.info(message, title,  {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      timeOut: 10000,
      positionClass: 'toast-top-right'
    })
  }

  showWarningDevice(message: any, title: any){
    this.toastr.warning(message, title,  {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      timeOut: 10000,
      positionClass: 'toast-top-right'
    })
  }

  showSuccess(message : any, title: any){
      this.toastr.success(message, title,  {
        tapToDismiss: true,
        closeButton: true,
        progressBar: true,
        progressAnimation: 'increasing',
        timeOut: 10000,
        positionClass: 'toast-bottom-right'
      })    
  }
  
  showError(message : any, title: any){
      this.toastr.error(message, title,  {
        tapToDismiss: true,
        closeButton: true,
        progressBar: true,
        progressAnimation: 'increasing',
        timeOut: 10000,
        positionClass: 'toast-bottom-right'
      })
  }
  
  showInfo(message : any, title: any){
      this.toastr.info(message, title,  {
        tapToDismiss: true,
        closeButton: true,
        progressBar: true,
        progressAnimation: 'increasing',
        timeOut: 10000,
        positionClass: 'toast-bottom-right'
      })
  }
  
  showWarning(message : any, title: any){
      this.toastr.warning(message, title,  {
        tapToDismiss: true,
        closeButton: true,
        progressBar: true,
        progressAnimation: 'increasing',
        timeOut: 10000,
        positionClass: 'toast-bottom-right'
      })
  }
}
