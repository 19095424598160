import { SimpleChange, ViewChild,  } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { MatRipple,  } from '@angular/material/core';
import { MqttControllerService } from 'src/app/mqtt/mqtt-controller.service';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith,  } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DeviceModalComponent } from '../device-modal/device-modal.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DeviceLinksBottomSheetComponent } from '../device-links-bottom-sheet/device-links-bottom-sheet.component';
import { NotificationToastService } from 'src/app/notification-toast.service';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import Util from 'src/app/util';
import { DeviceAddModalComponent } from '../device-add-modal/device-add-modal.component';
import { DeviceRemoveModalComponent } from '../device-remove-modal/device-remove-modal.component';
import { Constants } from 'src/app/constants';
import { Router } from '@angular/router';
import { SharedDevicesService } from 'src/app/shared-devices.service';
import { ChangeDetectorRef } from '@angular/core';
import { DeviceModalShareComponent } from '../device-modal-share/device-modal-share.component';




@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  /** Reference to the directive instance of the ripple. */


  devices: any = [];
  value = '';

  afterContent : number = 0;
  afterView : number = 0;


  myControl = new FormControl();
  options: any = [];
  filteredOptions: Observable<any[]> | any;

  constructor(private router: Router, public dialog: MatDialog, private _mqttControllerService: MqttControllerService, private _mqttService: MqttService,
    private _bottomSheet: MatBottomSheet,
    private _toast: NotificationToastService,
    private _sharedDevicesService : SharedDevicesService,
    private ref: ChangeDetectorRef,) { //update nos dados sem fazer refresh na tela
      // ref.detach();
      //   setInterval(() => { this.ref.detectChanges(); }, 2000);

      
    
    }
  @Input() deviceId: string = '';
  @Input() topic: string = '';
  @Input() payload: string = '';
  @Input() document: any[] = [];
  @Input() documentOfShared : any[] = [];
  @Input() sharedDevices: any = [];


  change : any;
  addDevice : string [] = [];  
  hasSharedDevice : boolean = false;
  @ViewChild(MatRipple) ripple: MatRipple | any;
  @Output() newItemEvent = new EventEmitter<any>();

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );   
      
      this.ref.detach(); //detacha os components 
      const studentsObservable = this._sharedDevicesService.getSharedDevicesObservable(this.sharedDevices);
      studentsObservable.subscribe((sharedDevicesData: any) => {
        //console.log('cryin', sharedDevicesData);
        
          this.sharedDevices = sharedDevicesData;
          this.hasSharedDevice = this.joinSharedDevices()
          
          if(this.hasSharedDevice){
            //se tiver dispositivos compartilhados detecta mudanças e reattach os componentes
            this.ref.detectChanges();
            this.ref.reattach();
          }
      });
      
    this.init();
  }


  ngOnChanges(changes : any){
    
  }

  init() {
    
    this._mqttService.messages.subscribe(data => {
      // console.info('devices : tópico : ', data.topic);
      // console.info('devices : payload: ', data.payload.toString());
      if (data.topic.includes("confirmation")) {
        
        let chipId = this.getChipIdFromTopic(data.topic)
        var description = this.getDescriptionFromChipId(chipId);

        /* this.documentOfShared.length == 0 evitar que o toast seja disparado duas vezes. */
        if(data.payload.toString() == "1" && !data.retain && this.documentOfShared.length == 0){
          this._toast.showWarningDevice("Dispositivo Acionado!" , description)
        } 

        if(data.payload.toString() == "0" && !data.retain && this.documentOfShared.length == 0 ){
          this._toast.showInfoDevice("Dispositivo Desligado!" , description)
        }
        this.setFilteredDevice(chipId, data.payload.toString())

      }

      if (data.topic.includes("/will/")) {
        let chipId = this.getChipIdFromTopic(data.topic)
        this.setFilteredDeviceWillTopic(chipId, data.payload.toString())
      }

      this.newItemEvent.emit(this.devices);

      //ordena por ativação
      // this.devices.sort((device: any) => {
      //   return device.isAcionado && !device.isWillTopic ? -1 : 1;
      // })

    });

    this.document.length == 0 ? this.selectSharedDevices() : this.selectDevices();
    //console.log('DISPOSITIVOS  ', this.devices)
    //console.log(this.options);
  }

  joinSharedDevices() : boolean{
    let hasSharedDevice = true;
    this.sharedDevices.forEach((device : any) => {
      device.forEach((device : any) => {

        this.devices.push({ device: device, isAcionado: false, isWillTopic: false, isShared: true });
        this.options.push(device.descricao)
        hasSharedDevice = true
      });
    });
    // console.log('DISPOSITIVOS + shared  ', this.devices);
    return hasSharedDevice ? true : false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.devices.filter((device: any) => device.device.descricao.includes(value))
    return this.options.filter((option: any) => option.toLowerCase().includes(filterValue));
  }

  triggerRipple() {
    
    this.ripple.launch({ centered: true });
  }

  getDescriptionFromChipId(chipId : string){
    var description = '';
    this.devices.filter((device : any) => {
      if(device.device.chipId == chipId){
        description = device.device.descricao;
        return device.device.descricao;
      }
    })
    return description;
  }

  publish(device: any, isAcionado: boolean) {
    // console.log(device);
    // console.log(device.mqtt_topic_publish_alarm[0].mqtt_topic_publish_alarm)
    let topic = device.mqtt_topic_publish_alarm[0].mqtt_topic_publish_alarm.replace("+", this.document[0].login + ' - ' + this.document[0].nome + ' ' + this.document[0].sobrenome)
    // console.log(topic)
    let payload = isAcionado ? "1" : "0";
    this._mqttControllerService.publishToTopic(topic, payload, false)

  }

  setFilteredDeviceWillTopic(chipId: string, payload: string) {
    this.devices.filter((device: any) => {
      if (device.device.chipId === chipId) {
        if (payload == '1') {
          device.isWillTopic = true;
        } else {
          device.isWillTopic = false;
        }

      }
    })
  }

  setFilteredDevice(chipId: string, payload: string) {
    this.devices.filter((device: any) => {
      if (device.device.chipId === chipId) {
        if (payload == "1") {
          device.isAcionado = true;
        }

        if (payload == "0") {
          device.isAcionado = false;
        }

      }
      return device;
    })
  }

  getChipIdFromTopic(topic: string): string {
    return topic.substring(0, topic.indexOf("/"));
  }

  getImageIcon(device : any){
    return Util.getImageIcon(device);
  }

  selectDevices() {

    this.document.forEach(doc => {
      for (let i = 0; i < doc.devices.length; i++) {
        //this.options.push({chipId: doc.devices[i].chipId, description: doc.devices[i].descricao});
        this.options.push(doc.devices[i].descricao);
        this.devices.push({ device: doc.devices[i], isAcionado: false, isWillTopic: false, isShared: false });
      }  
    })
  }

  selectSharedDevices(){
    this.document = this.documentOfShared;
  }

  getDeviceState(device: any){
    return Util.getDeviceState(device);
  }

  isActionable(type : string){
    return Constants.ACTIONABLES.includes(type)
  }

  openBottomSheet(device: any) {
    this._bottomSheet.open(DeviceLinksBottomSheetComponent, {data: device});
  }

  btn() {

    this._mqttControllerService.publishToTopic('/test/test', '123', false)
  }

  getContingencyInternetToolTip(device: any){
    if(!device.contingency_network_ssid){
      return "Esse dispositivo não possui internet de contingência configurada."
    } else {
      return "SSID: " + device.contingency_network_ssid + "\n" + 
             "Senha: " + device.contingency_network_password 
    }
  }

  getColorStyleContingencyInternet(device : any){
    if(device.contingency_network_ssid != ''){
      return 'green'
    }

    return 'black';
  }


  openDeviceDialog(device: any) {

    this.dialog.open(DeviceModalComponent, {
      data: {device : device, document: this.document},
      height: '90%',
      width: '90%',
      //panelClass: ['md:w-3/5', 'w-full'],
      
    });
  }

  openAddDeviceModal(){
    this.dialog.open(DeviceAddModalComponent, {
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      height: '80%',
      width: '80%',
      data: this.document
      
    })
  }

  openModalRemoveDevice(chipId: string, description: string){
    this.dialog.open(DeviceRemoveModalComponent, {
      data: {chipId: chipId, description: description}
    })
  }

  giveChipIdSelectContract(chipId: string) : string{
    let result : string = '';

    for(let i = 0; i < this.document.length; i++){
      this.document[i].devices.filter((device : any) => {
        if(device.chipId == chipId){
          result = this.document[i].contrato;
        }
      });
      
    }
    return result;
  }

  openModalShareDevice(chipId: string, description: string){
    var contract = this.giveChipIdSelectContract(chipId);
    this.dialog.open(DeviceModalShareComponent, {
      data: {chipId: chipId, contract: contract, description: description},
      height: '30%',
      width: '60%',
    })
  }

  

  getColor(device: any) {

    if(device.isWillTopic){
      return '#CDCCD7';
    }

    if(device.isAcionado){
      return 'warn';
    }

    return 'primary';
    
  }

  getClassAnimationDevice(device: any) : string{
    if(device.isWillTopic){
      return '';
    }

    if(device.isAcionado){
      return 'animate__animated animate__heartBeat animate__infinite';
    }

    return '';
  }

  getStyleBorder(device : any): string{
    if(device.isWillTopic){
      return '1px solid rgba(0,0,0, 0.3)';
    }

    if(device.isAcionado){
      return '1px solid rgba(255,0,0, 0.4)'
    } 
    
    return '';
  }

  getStyleBackgroundColor(device: any) : string{
    if(device.isWillTopic){
      return '#CDCCD7'
    }

    if(device.isAcionado){
      return 'red';
    }
    
    return '#152f55';
  }

}
