
<div class="row" style="margin: 20px;">
    <div class="col col-lg-8">
        <mat-form-field class="col col-lg-10" appearance="fill" autofilled="true">
            <mat-label>Pesquisa pelo nome do dispositivo</mat-label>
            <input [ngModel]=findValue (ngModelChange)="findValue = $event; onChange(findValue)" matInput type="text" [formControl]="findFormControl">
            <button *ngIf="findFormControl.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="findFormControl.setValue('')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

    </div>
    <div class="col-2" style="margin-top: 2px;">
        <button  mat-fab matTooltip="Clique aqui para atualizar os logs" (click)="refresh()"
          style="background-color: green;" aria-label="Clique aqui para atualizar os logs">
          <mat-icon>refresh</mat-icon>
        </button>
  
    </div>
    <!-- <div class="col" style="margin: 1%;">
        <button class="btn btn-outline-success">Atualizar</button>
    </div> -->

    <p>Mostrando {{!allLogsPage  ? 0 : allLogsPage?.length}} de {{!allLogs ? 0 : allLogs?.length}}</p>

    
</div>


<div class="row sm-2" style="margin-left: 10%; text-align: center; overflow: hidden;">
    <mat-button-toggle-group name="fontStyle" style="border: none; text-align: center;">
        <mat-button-toggle matTooltip="Todas informações" (change)="applyFilter($event)" value="todos">Remove Filtros</mat-button-toggle>
        <mat-button-toggle matTooltip="Sinal WiFi" (change)="applyFilter($event)" value="2">
            <mat-icon>network_check</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Dispositivo OFFLINE" (change)="applyFilter($event)" value="4">
            <mat-icon>wifi_off</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Nível da Bateria" (change)="applyFilter($event)" value="3">
            <mat-icon>battery_alert</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Alarme" (change)="applyFilter($event)" value="1">
            <mat-icon>notifications_active</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Confirmação de Recebimento de Alarme" (change)="applyFilter($event)" value="8">
            <mat-icon>check</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Restart dispositivo" (change)="applyFilter($event)" value="5">
            <mat-icon>refresh</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Reset dispositivo" (change)="applyFilter($event)" value="6">
            <mat-icon>restart_alt</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>


<!-- <div class="row sm-2" style="margin-left: 10%; text-align: justify;">
    Filtros:
    <div class="col-sm-1" style="text-align: center;">
        <button mat-icon-button matTooltip="Sinal do WiFi">
            <mat-icon>network_check</mat-icon>
        </button>
    </div>
    <div class="col-sm-1" style="text-align: center;">
        <button mat-icon-button matTooltip="Filtrar informações de dispositivos Offline">
            <mat-icon>wifi_off</mat-icon>
        </button>
    </div>
    <div class="col-sm-1" style="text-align: center;">
        <button mat-icon-button matTooltip="Filtrar bateria em alerta">
            <mat-icon>battery_alert</mat-icon>
        </button>
    </div>
    <div class="col-sm-1" style="text-align: center;">
        <button mat-icon-button matTooltip="Filtrar eventos de acionamento">
            <mat-icon>notifications_active</mat-icon>
        </button>
    </div>
    <div class="col-sm-1" style="text-align: center;">
        <button mat-icon-button matTooltip="Confirmação de recebimento de comando do dispositivo">
            <mat-icon>check</mat-icon>
        </button>
    </div>


    
</div> -->

<div class="row" *ngIf="_isLoading">
    <mat-progress-bar style="text-align: start;" mode="indeterminate"></mat-progress-bar>
</div>

<mat-card [style.border]="getStyleBorder(log)" style="margin: 20px;" *ngFor="let log of allLogsPage">
    <mat-card-header>

        <div mat-card-avatar style="text-align: center; margin-top: 1%;">
            <mat-icon [class]="getAnimatedClass(log)" [style.color]="getIconColor(log)">{{getIconLog(log)}}</mat-icon>
        </div>
        <mat-card-title><strong>{{getDeviceNameGivenChipId(log.chipId)}}</strong></mat-card-title>
        <mat-card-subtitle><b>Chip ID: </b>{{log.chipId}}</mat-card-subtitle>
        <mat-card-subtitle><strong>{{selectUserName(log.user)}}</strong></mat-card-subtitle>
        <mat-card-subtitle><p>{{log.dataHora}}</p></mat-card-subtitle>
        
        <mat-card-subtitle><p>{{extractMessageFromPayload(log)}}</p></mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>
        <!-- <mat-selection-list #user [multiple]="false">
            <mat-list-option (click)="getLog(log)">
                
                
                <div class="col"> 
                    <p>{{log.payload}}</p>
                </div>
                
            </mat-list-option>
        </mat-selection-list> -->


    </mat-card-content>
    <mat-card-actions>
        <!-- <button mat-raised-button >ACTIVE</button> -->
    </mat-card-actions>
    <mat-card-actions>
    </mat-card-actions>
    <mat-card-footer>

    </mat-card-footer>
</mat-card>




<!-- <mat-paginator [length]="allLogs?.length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="handlePaginator($event)">
</mat-paginator> -->