import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import Util from '../util';

@Injectable({
  providedIn: 'root'
})

export class DeviceService {
  session : any;
  
  constructor(private http: HttpClient,) { }

  addDevice(chipId: string, contract : string, type: string){
    return this.http.post(Constants.API_ENDPOINT + 'dispositivo/cadastrar/' + contract + '&' + chipId + '&' + type, null, {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });
  }

  removeDevice(chipId: string){
    return this.http.delete(Constants.API_ENDPOINT + 'dispositivo/excluir/' + chipId, {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });
  }

  updateDescription(body : any){
    return this.http.post(Constants.API_ENDPOINT + 'devices/editar', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
        "Access-Control-Allow-Credentials": "true",
        'Access-Control-Allow-Origin': '*',
        'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
        'Authorization': 'Bearer ' + Util.getToken()}),                      
    });
  }

  saveContingencyInternet(body : any ){
    return this.http.post(Constants.API_ENDPOINT + 'configurar/contingencia', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
        "Access-Control-Allow-Credentials": "true",
        'Access-Control-Allow-Origin': '*',
        'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
        'Authorization': 'Bearer ' + Util.getToken()}),  
    })
  }

  updatePermission(chipId: string, loginShared: string, newPermission: string){
    return this.http.post(Constants.API_ENDPOINT + 'devices/acessos/alterar/permissao/' + chipId + '&' + loginShared + '&' + newPermission, null, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
        "Access-Control-Allow-Credentials": "true",
        'Access-Control-Allow-Origin': '*',
        'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
        'Authorization': 'Bearer ' + Util.getToken()}),  
    })
  }

  shareDevice(contract: string, loginShared: string, chipId: string, permission: string){
    return this.http.post(Constants.API_ENDPOINT + `grupos/compartilhar/device/${contract}&${loginShared}&${chipId}&${permission}` , null, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
        "Access-Control-Allow-Credentials": "true",
        'Access-Control-Allow-Origin': '*',
        'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
        'Authorization': 'Bearer ' + Util.getToken()}),  
    })
  } 

}
