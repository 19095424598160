export class Constants {
    public static API_ENDPOINT='/api/';
    public static API_ENDPOINT_LOGS='/logs/';
    public static ACTIONABLES = ['2', '3', '6', '8', '10', '11']

    public static BOTAO_SANITARIO_PNE = '1';
    public static ALERTA_SANITARIO_PNE = '2';
    public static MODULO_RELE_NA_NF = '3';
    public static ENTRADA_ATIVACAO_ON = '4';
    public static ENTRADA_LIGA_DESLIGA = '5';
    public static SINALIZADOR = '6';
    public static BOTAO_CAMPAINHA = '7';
    public static SINALIZADOR_CAMPAINHA = '8';
    public static ENTRADA_CONVENCIONAL = '9';
    public static SINALIZADOR_DE_INCENDIO = '10';
    public static RELE_PULSO = '11'
    public static MONITOR_SAIDA_DE_SIRENE = '12'

    public static PRIMARY_COLOR =  '#152f55';
    public static SECONDARY_COLOR = '#54b3d2'

    public static ALARM_TOPIC_TYPE = '1';
    public static RRSI_TOPIC_TYPE = '2';
    public static BATTERY_TOPIC_TYPE = '3';
    public static WILL_TOPIC_TYPE = '4';
    public static RESTART_TOPIC_TYPE = '5';
    public static RESET_TOPIC_TYPE = '6';
    public static START_TOPIC_TYPE = '7';
    public static CONFIRMATION_TOPIC_TYPE = '8';
    public static BASEOFF_TOPIC_TYPE = '9';
    public static LOOPFAIL_TOPIC_TYPE = '10';
    public static STATE_TOPIC_TYPE = '11';

    public static TOPIC_CONFIRMATION = '/confirmation/';
    public static TOPIC_ALARM = '/alarm/';
    public static TOPIC_WILL = '/will/';
    public static TOPIC_STATE = '/state/';
    public static TOPIC_START = '/start/';
    public static TOPIC_BATTERY = '/battery/';
    public static TOPIC_RRSI = '/rrsi/';
    public static TOPIC_RESTART = '/restart/';
    public static TOPIC_RESET = '/reset/';
 }