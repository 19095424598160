import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-end',
  templateUrl: './header-end.component.html',
  styleUrls: ['./header-end.component.scss']
})
export class HeaderEndComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
