<ng-container *ngFor="let doc of document">
  <mat-card class="contract-card">
    <mat-card-header style="margin-bottom: 2%;">
      <div mat-card-avatar class="contract-header-image"></div>
      <mat-card-title>{{doc.contrato}}</mat-card-title>
      <mat-card-subtitle>{{doc.contratoDescricao}}</mat-card-subtitle>


        

    </mat-card-header>

    <mat-card-content>

      <div class="row row-lg-10">
        <div class="col md-6">
          <span>Máx. Compartilhamento: <b>{{doc.quantidadeMaximaCompartilhar}}</b></span>
        </div>
        <div class="col md-6">
          <span>Máx. Dispositivos: <b>{{doc.quantidadeMaximaDispositivos}}</b> </span>
        </div>
        <div class="col md-6">
          <span>Total dispositivos: <b>{{doc.devices.length}} de {{doc.quantidadeMaximaDispositivos}}</b></span>
        </div>
      </div>

      <div class="row row-sm-2" style="margin-top: 2%;">
        <div class="col col-sm-1" style="text-align: end;">
          <button  mat-fab matTooltip="Clique aqui para COMPARTILHAR este contrato" (click)="openContractModalShare(doc.contrato, doc.contratoDescricao)"
          style="background-color: green;" aria-label="Clique aqui para COMPARTILHAR este contrato">
            <mat-icon>share</mat-icon>
          </button>
        </div>
      </div> 

      

      <!-- <div class="row row-sm-2" style="margin-top: 2%;">
        <div class="col col-sm-1" style="text-align: end;">
          <button  mat-fab matTooltip="Clique aqui para COMPARTILHAR este contrato" (click)="openContractModalShare(doc.contrato, doc.contratoDescricao)"
          style="background-color: green;" aria-label="Clique aqui para COMPARTILHAR este contrato">
            <mat-icon>share</mat-icon>
          </button>
        </div>
      </div> -->
      
    </mat-card-content>
    <mat-card-actions>
      <!-- <button mat-raised-button >ACTIVE</button> -->
    </mat-card-actions>
    <mat-card-actions>
    </mat-card-actions>
    <mat-card-footer>

    </mat-card-footer>
  </mat-card>
</ng-container>