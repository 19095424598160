import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/api/device.service';
import { NotificationToastService } from 'src/app/notification-toast.service';

@Component({
  selector: 'app-device-modal-access',
  templateUrl: './device-modal-access.component.html',
  styleUrls: ['./device-modal-access.component.scss']
})
export class DeviceModalAccessComponent implements OnInit {

  @Input() data: any;
  @Output() newItemEvent = new EventEmitter<any>();
  constructor(
    private _toast: NotificationToastService,
    private _deviceService: DeviceService,
  ) { }

  _hasChange : boolean = false;
  _isLoading : boolean = false;
  ngOnInit(): void {
  } 

  getRemoveButtonTooltip(name : string) : string{
    return "Clique aqui para remover o usuário \n\n" + name;
  }

  getAccessTypeText(access: string) : string {
    if(access == 'r'){
      return 'Leitura'
    } else {
      return 'Escrita'
    }
  }

  getAccessIcon(access: string) : string[] {
    let result : any[] = [];
    if (access == 'r'){
      return result = ['visibility', ]
    } else {
      return result = ['visibility', 'edit']
    }
  }

  setPermission(user: any, newPermission : string, loginShared: string){
    this._isLoading = true;
    var currentPermission = user.permissao;
    

    
    if(currentPermission != newPermission){
      this._hasChange = true;
      this._deviceService.updatePermission(this.data.device.device.chipId, loginShared, newPermission).subscribe(data => {
      }, err => {
        console.log(err);
        if(err.status == 200){
          this._toast.showSuccess(err.error.text, "Sucesso !")
        } else {
          this._toast.showError(err.error.text, "ERRO")
        }

        this._isLoading = false;
      })
      return true;
      
    } else {
      this._hasChange = false;
      return false;
    }
  }

  test(){

  }
  
  

}
