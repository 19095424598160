<div class="row-tabs">
  <div>
    <mat-tab-group dynamicHeight [selectedIndex]="0">
      <mat-tab label="Dispositivos">
        <ng-template mat-tab-label>
          <mat-icon style="margin-right: 20px;" class="tab-icon-devices">developer_board</mat-icon>
          <b>Dispositivos</b>
        </ng-template>
        <app-devices [document]="document" [sharedDevices]="sharedDevices" (newItemEvent)="getUpdatedDevices($event)">
        </app-devices>
      </mat-tab>

      <mat-tab label="Central">
        <ng-template mat-tab-label>
          <mat-icon style="margin-right: 20px;" class="tab-icon-central">grid_on</mat-icon>
          <b>Central</b>
        </ng-template>
        <app-grid-devices [document]=document [devices]="devices"></app-grid-devices>
      </mat-tab>

      <mat-tab label="Contratos">
        <ng-template mat-tab-label>
          <mat-icon style="margin-right: 20px;" class="tab-icon-contracts">gavel</mat-icon>
          <b>Contratos</b>
        </ng-template>
        <app-contracts [document]="document"></app-contracts>
      </mat-tab>

      <mat-tab label="Terceiros">
        <ng-template mat-tab-label>
          <mat-icon style="margin-right: 20px;" class="tab-icon-shared">insights</mat-icon>
          <b>Terceiros</b>
        </ng-template>
        <app-shared [document]=document [sharedDevices]="sharedDevices"></app-shared>
      </mat-tab>

      <mat-tab label="Ocorrências">
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon-ocurrencies">notifications_active</mat-icon>
          <b>Ocorrências</b>
        </ng-template>
        <app-occurrences></app-occurrences>
      </mat-tab>

      <mat-tab label="Logs">
        <ng-template mat-tab-label>
          <mat-icon style="margin-right: 20px;" class="tab-icon-logs">assessment</mat-icon>
          <b>Logs</b>
        </ng-template>
        <app-logs [document]=document [sharedDevices]=sharedDevices></app-logs>
      </mat-tab>

      <mat-tab label="Console">
        <ng-template mat-tab-label>
          <mat-icon style="margin-right: 20px;" class="tab-icon-logs">dvr</mat-icon>
          <b>Console</b>
        </ng-template>
        <app-console [document]=document [sharedDevices]=sharedDevices></app-console>
      </mat-tab>

    </mat-tab-group>
  </div>

</div>