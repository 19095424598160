<div *ngIf="dataList.length == 0">
    Não há informações no momento.
</div>
<div ngx-auto-scroll observe-attributes>
    <mat-nav-list dense *ngFor="let data of dataList.slice().reverse()">

        <div class="animate__animated animate__fadeInRight" style="text-align: center;">
            <b>{{data.deviceDescription}} </b>
            <mat-icon [style.color]="data.color">{{data.icon}}</mat-icon>
        </div>
        <div class="row" style="text-align: center; justify-content: center;">
            <div style="font-size: small;">{{data.date}}</div>
        </div>

        <mat-list-item [matTooltip]="getMatTooltip(data.matTooltip)">
            <div class="row animate__animated animate__fadeInRight">
                <span>{{data.payload}}</span>
            </div>


        </mat-list-item>
        <mat-divider></mat-divider>
    </mat-nav-list>
</div>