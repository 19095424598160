
<mat-selection-list #devices>
    
    <div *ngFor="let device of allDevices">
        <mat-list-option [disabled]="isDisabled()" [matTooltip]='getTypeText(device.tipo)'  checkboxPosition="before" *ngIf="isEligibileToLink(device.tipo) && device.chipId != data.device.device.chipId" [value]="device">

            <div class="row">
                <div class="col">
                    {{device.descricao}}    
                </div>
                <div class="col" style="text-align: end;">
                    <img style="background-color: #152f55;" width="40" height="25" [src]="getImageIcon(device.tipo)">
                </div>
            </div>
        </mat-list-option>
    </div>
    
  </mat-selection-list>
  
  <p style="margin-top: 5%;">
    Total a vincular: {{devices.selectedOptions.selected.length}}
  </p>

  <mat-divider style="margin-top: 3%; margin-bottom: 3%;"></mat-divider>
  <div *ngFor="let valor of devices.selectedOptions.selected; let i = index;">
      <div class="row">
        <div class="col" >
            <div *ngIf="i == 0">
                {{data.device.device.descricao}}
                <mat-divider ></mat-divider>
            </div>
        </div>
        <div class="col">
            
        </div>
        <div class="col" style="text-align: center;">
            <mat-icon *ngIf="i == 0" style="margin-left: 5%;">multiple_stop</mat-icon>
        </div>
        <div class="col" style="text-align: end;" >
            ->
        </div>
        <div class="col">
            {{valor.value.descricao}}
            <mat-divider ></mat-divider>
        </div>
      </div>
  </div>

  <div style="text-align: center;" *ngIf="devices.selectedOptions.selected.length > 0">
    <ng-container *ngIf="_isLoading else elseBlock">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>

    <ng-template #elseBlock>
        <button style="margin-top: 5%;" class="btn btn-outline-success" (click)="link(devices.selectedOptions.selected)" >Vincular</button>
    </ng-template>
    
  </div>
  

