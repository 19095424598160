<p>Tem certeza que deseja excluir o dispositivo?</p>
<h4>{{dataDialog.description}}</h4>

<ng-container *ngIf="_isLoading else elseBlock">
    <mat-spinner diameter="40" style="margin:6% auto;" ></mat-spinner>
</ng-container>

<ng-template #elseBlock>
    <div class="container d-flex justify-content-center">
        <button class="btn btn-outline-danger" (click)="removeDeviceApi()">Excluir</button>
    </div>
</ng-template>



