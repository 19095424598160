import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractService } from 'src/app/api/contract.service';

@Component({
  selector: 'app-contract-modal-unshare',
  templateUrl: './contract-modal-unshare.component.html',
  styleUrls: ['./contract-modal-unshare.component.scss']
})
export class ContractModalUnshareComponent implements OnInit {

  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ContractModalUnshareComponent>,
  private _contractService : ContractService) { }

  ngOnInit(): void {
  }

}
