<ngb-alert type="info" (click)=close() >
    <strong>Atenção!</strong> Digite o Chip ID e seu respectivo tipo, exemplo 123123123-1
  </ngb-alert>
<div class="col">
    <div class="row">
        <div class="container d-flex justify-content-center" style="margin-top: 5%;">
            <mat-form-field class="w-75 p-3" appearance="fill">
                <mat-label>Chip ID</mat-label>
                <input matInput maxlength="20" type="text" [formControl]="chipIdFormControl">
                <button *ngIf="chipIdFormControl.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="chipIdFormControl.setValue('')">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error *ngIf="chipIdFormControl.hasError('required')">
                    <p>O Chip ID é obrigatório ser preenchido.</p>
                </mat-error>
                <mat-hint >{{chipIdFormControl.value?.length || 0}}/20</mat-hint>
            </mat-form-field>
            
            <div class="d-flex align-self-center">
                <mat-icon>minimize</mat-icon>
            </div>
            

            <mat-form-field class="w-50 p-3" appearance="fill">
                <mat-label>Tipo de dispositivo</mat-label>
                <input matInput maxlength="2" type="text" [formControl]="typeFormControl">
                <button *ngIf="typeFormControl.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="typeFormControl.setValue('')">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error *ngIf="typeFormControl.hasError('required')">
                    <p>O Tipo é obrigatório ser preenchido.</p>
                </mat-error>
                <mat-hint >{{typeFormControl.value?.length || 0}}/2</mat-hint>
            </mat-form-field>
        </div>

    </div>

    <ng-container *ngIf="isLoading else elseBlock" >
        <mat-spinner style="margin:6% auto;" ></mat-spinner>
    </ng-container>    

    <ng-template #elseBlock>
        <div *ngIf="chipIdFormControl.value?.length > 0 && typeFormControl.value?.length > 0 " class="row" style="margin-top: 6%;">
            <div class="container d-flex justify-content-center" >
                <button mat-raised-button [disabled]="buttonDisabled" (click)="addDevice(chipIdFormControl.value, typeFormControl.value)" class="btn btn-outline-success">Cadastrar</button>
            </div>
        </div>
    </ng-template>
    
    <div class="row" *ngIf="message" >
        <ngb-alert [type]="getAlertType()" style="margin-top: 5%;">
            <strong>Atenção!</strong> {{message}}
        </ngb-alert>    
    </div>
    
    

</div>