import { Component, Input, OnInit,  } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() document : any[] = [];
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  handleHome(){
    window.location.reload();
    
  }

  logout(){
    //navega com reload de página 'para iniciar o sistema do zero'.
    sessionStorage.removeItem("session");
    
    
    this.router.navigate(['/login']).then(() => window.location.reload());
  }
  
  

}
