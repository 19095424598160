import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceService } from 'src/app/api/device.service';
import { NotificationToastService } from 'src/app/notification-toast.service';

@Component({
  selector: 'app-device-modal-share',
  templateUrl: './device-modal-share.component.html',
  styleUrls: ['./device-modal-share.component.scss']
})
export class DeviceModalShareComponent implements OnInit {
  login = '';
  matMenuDescription = 'Selecione'
  _isLoading = false;
  permission = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _deviceService: DeviceService,
    private _toast : NotificationToastService,
    private _dialogRef: MatDialogRef<DeviceModalShareComponent>,
    ) { }

  ngOnInit(): void {
  }

  selectPermission(permissionText: string, permission: string){
    this.matMenuDescription = permissionText;
    this.permission = permission;
  }

  getShareButtonTooltip() : string{
    return `Compartilhar esse dispositivo com o login: ${this.login}`
  }

  shareDevice(){
    this._isLoading = true;
    this._deviceService.shareDevice(this.data.contract, this.login, this.data.chipId, this.permission).subscribe(data => {
      let result = data as any;


      this._toast.showSuccess(result.mensagem, "Sucesso -> " + this.login)
      setTimeout(() => {
        this.closeAndRefresh();
        this._isLoading = false;
      },
        2000);
    }, err => {
      this._toast.showError("Ocorreu um erro ao tentar compartilhar esse dispositivo com o login: " + this.login, "ERRO" ); 
      this._isLoading = false;
    } )

    
  }

  closeAndRefresh() {
    this._dialogRef.close();
    window.location.reload();
  }
}
