import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'devicepipe'
})
export class DevicepipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value.filter((device : any) => device.device.descricao.toLowerCase().includes(args[0].toLowerCase()));
  }

}
