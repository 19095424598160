<div class="row" style="text-align: center;">
    <b>{{data.description}}</b>
</div>

<mat-form-field class="w-75 p-3 animate__animated animate__fadeInLeft">
    <mat-label>Digite o login sem pontos ou traços</mat-label>
    <input #inputLogin matInput type="text" [(ngModel)]="login">
    <button *ngIf="login" matSuffix mat-icon-button aria-label="Clear" (click)="login=''">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<button mat-button class="animate__animated animate__fadeInRight" [matMenuTriggerFor]="menu">{{matMenuDescription}}
    <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="selectPermission('Leitura', 'r')">
        Leitura
    </button>
    <button mat-menu-item (click)="selectPermission('Escrita', 'w')">
        Escrita
    </button>
</mat-menu>

<div *ngIf="_isLoading" style="text-align: center;">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="login.length >= 11 && !_isLoading && matMenuDescription != 'Selecione'" style="text-align: center;">
    <button (click)="shareDevice()" matTooltip="getShareButtonTooltip()"
        class="btn btn-outline-success">Compartilhar</button>
</div>