import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import Util from '../util';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  constructor(private http: HttpClient,) { }

  linkDevices(chipId: string, linkChipId: string){
    return this.http.post(Constants.API_ENDPOINT + 'vinculos/incluir/' + chipId + '&' + linkChipId, null, {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });
  }

  unlinkDevices(chipId: string, linkChipId: string){
    return this.http.delete(Constants.API_ENDPOINT + 'vinculos/excluir/' + chipId + '&' + linkChipId, {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });
  }
}
