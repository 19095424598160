<div *ngIf="logs == 'undefined'">
    Carregando . . . 
</div>

<div *ngIf=isLoading>
    <mat-spinner diameter="40" style="margin:10% auto; text-align: center; " ></mat-spinner>
</div>


<mat-card style="margin: 20px;" *ngFor="let log of logs">
    <mat-card-header>

        <div mat-card-avatar style="text-align: center; margin-top: 1%;">
            <mat-icon [class]="getAnimatedClass(log)" [style.color]="getIconColor(log)">{{getIconLog(log)}}</mat-icon>
        </div>
        <mat-card-title><strong>{{getDeviceNameGivenChipId(log.chipId)}}</strong></mat-card-title>
        <mat-card-subtitle><b>Chip ID: </b>{{log.chipId}}</mat-card-subtitle>
        <mat-card-subtitle><strong>{{selectUserName(log.user)}}</strong></mat-card-subtitle>
        <mat-card-subtitle><p>{{log.dataHora}}</p></mat-card-subtitle>
        
        <mat-card-subtitle><p>{{extractMessageFromPayload(log)}}</p></mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>


    </mat-card-content>
    <mat-card-actions>
        <!-- <button mat-raised-button >ACTIVE</button> -->
    </mat-card-actions>
    <mat-card-actions>
    </mat-card-actions>
    <mat-card-footer>

    </mat-card-footer>
</mat-card>