import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, of, } from 'rxjs';
import { catchError, tap, } from 'rxjs/operators';

//produção
const apiReference = '/api/'
const apiReferenceLog = '/logs/'
@Injectable({
  providedIn: 'root'
})


export class LoginServiceService {
  
  constructor(private http: HttpClient,) { }
  
  statusCode : number = 0;

  auth(login : string, password : string){

    //return this.http.post(apiReference + 'auth/MzYzMTUxMTM4ODU=&c2VtcHJlMTIz', null)
    return this.http.post(apiReference + 'auth/' + btoa(login) + '&' + btoa(password), null)
    .pipe(
      catchError(this.handleError('- Auth ', {error: "Erro !", statusCode: this.statusCode}))
    );
  }

  authLog(login : string, password : string){
    return this.http.post(apiReferenceLog + 'auth/' + btoa(login) + '&' + btoa(password), null)
    .pipe(
      catchError(this.handleError('- Auth ', {error: "Erro !", statusCode: this.statusCode}))
    );
  }

   handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      
      console.error(error);
      console.log(error.status)
      this.statusCode = error.status;

      return of(error as T);
    };
  }
}
