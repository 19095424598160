export const environment = {
  firebase:  {
    apiKey: "AIzaSyBr0HCgFOPLCCr8Pp2v8kfKU-NobkuAR_o",
    authDomain: "sempreiot-9f4ce.firebaseapp.com",
    databaseURL: "https://sempreiot-9f4ce.firebaseio.com",
    projectId: "sempreiot-9f4ce",
    storageBucket: "sempreiot-9f4ce.appspot.com",
    messagingSenderId: "949524430544",
    appId: "1:949524430544:web:a7d45f3d8fdbce4167e8d5"
  },
  production: true,
  hmr: false,
	http: {
		apiUrl: '<https://api.myweb.com>',
	},
	mqtt: {
		server: 'sempreiot.ddns.net',
		protocol: "wss",
		port: 9002
	}
};
