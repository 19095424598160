import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import Util from '../util';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http: HttpClient,) { }


  getLastOcurrencies(body : any){
    return this.http.post(Constants.API_ENDPOINT_LOGS + 'logs/ocorrencias' , body, {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getTokenLog()}),                      
      });
  }

  getLastOcurrenciesOfDevice(chipId: string){
    return this.http.get(Constants.API_ENDPOINT_LOGS + '/logs/dia/' + chipId , {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getTokenLog()}),                      
      });
  }
}
