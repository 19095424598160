import { Component, OnInit } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { Constants } from 'src/app/constants';
import Util from 'src/app/util';

@Component({
  selector: 'app-occurrences',
  templateUrl: './occurrences.component.html',
  styleUrls: ['./occurrences.component.scss']
})
export class OccurrencesComponent implements OnInit {

  constructor(public _mqttService: MqttService) { }
  occurrencesData : any[] = [];    

  
  ngOnInit(): void {
    this._mqttService.messages.subscribe(data => {
      
      let chipId = this.getChipId(data.topic);
      
      if(data.topic.includes(Constants.TOPIC_WILL)){
        
      } else {
        if(data.topic.includes(Constants.TOPIC_CONFIRMATION)){
          this.handleConfirmation(chipId, data);
        }
      }
      
    })
  }

  handleConfirmation(chipId: string, data : any){
    if(data.payload.toString() === '1'){
      this.occurrencesData.push(data);
    }

    if(data.payload.toString() === '0'){
      
      let index = this.occurrencesData.findIndex(d => d.id == chipId);
      if(index >= 0 ){
        this.occurrencesData.splice(index, 1);//remove 
      }
      
    }
  }

  getChipId(topic : string) : string{
    return Util.getChipIdFromTopic(topic);
  }

}
