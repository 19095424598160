import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MqttService } from 'ngx-mqtt';
import { LinksService } from 'src/app/api/links.service';
import { NotificationToastService } from 'src/app/notification-toast.service';
import Util from 'src/app/util';

@Component({
  selector: 'app-device-modal-linkeds',
  templateUrl: './device-modal-linkeds.component.html',
  styleUrls: ['./device-modal-linkeds.component.scss']
})
export class DeviceModalLinkedsComponent implements OnInit {
  @Input() data: any;
  @Output() newItemEvent = new EventEmitter<any>();

  constructor(
    private _linksService : LinksService,
    private _mqttService: MqttService,
    private _toast : NotificationToastService,
    public _dialogRef: MatDialogRef<DeviceModalLinkedsComponent>,) { }

  _isLoading : boolean = false;
  _anySuccess : boolean = false;

  ngOnInit(): void {
    
  }

  getChipIdText(id_vinculado : string){
    return `Chip ID: ${id_vinculado}`
  }

  isDisabled(){
    var result = this.data.device.device.grupo.filter((group : any) => group.loginCelular == this.data.document[0].login)
    return result.permissao == 'r' ? true : false;  
  }

  unlink(selectedToUnlink : any){

    selectedToUnlink.forEach((element: any, index: any) => {
      console.log(element.value)
      this._linksService.unlinkDevices(this.data.device.device.chipId, element.value.id_vinculado)
        
        .subscribe((data: any) => {
          this._isLoading = true; 
          let result = data as any;
          
          this.restarDevice(element.value.topicoRestart)
          
          this.newItemEvent.emit(true);
          this._toast.showSuccess("Dispositivo(s) desvinculado(s) com sucesso ! Aguarde o restart dos dispositivos para surtir efeito.", element.value.descricao);
          this.restarDevice(this.data.device.device.mqtt_topic_subscribe_restart)
        }, 
        err => {          
          this._toast.showError("Erro ao tentar desvincular com o dispositivo.", element.value.descricao);
          this._isLoading = false;
        })

    })
  }

  restarDevice(restartTopic: any){
    this._mqttService.publish(Util.removeWildCardFromTopic(restartTopic), 'r', {retain: false} ).subscribe(
      ()=> {
        console.log(Util.removeWildCardFromTopic(restartTopic), 'r');
        this._toast.showInfoDevice("Restart para vínculos", "O dispositivo será reiniciado para surtir efeitos de vínculos.")
      },
      (error) => {
        console.log('[ATENÇÃO] - ERRO ', error);
      }
    );
  }

}
