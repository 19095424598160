import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginServiceService } from './login/login-service.service';
import { DocumentService } from './api/document.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private loginService: LoginServiceService, private documentService: DocumentService) { }
  session : any;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 403) {
            console.log('--------- 403 ----------')

            this.session = sessionStorage.getItem('session');
            let sessionJson = JSON.parse(this.session);
            let login  = atob(sessionJson.l);
            let password = atob(sessionJson.p);

            this.loginService.auth(login, password).subscribe(data => {
              console.log(data);
              
              let result = data as any;

              if (result.error) {
                console.log(result.error);
              } else {

                this.loginService.authLog(login, password).subscribe(data => {
                  console.log('go home');
                let token = btoa(result.token);
                let resultLog = data as any;
                let tokenLog = btoa(resultLog.token);

                sessionStorage.setItem("session", JSON.stringify({ token: token, tokenLog: tokenLog, loggedIn: true, l: sessionJson.l, p: sessionJson.p }))
                
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/home']);
                })
                
               
              }
            });
            
            
            return;
          }
          
        }
      }));
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
];