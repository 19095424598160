

<app-menu [document]="document"></app-menu>

<ng-container *ngIf="document else elseBlock" >
    <app-body [document]="document" [sharedDevices]="sharedDevices"></app-body>
</ng-container>

<ng-template #elseBlock>
    <mat-spinner style="margin:0 auto;" ></mat-spinner>
</ng-template>


