import { Input, SimpleChange } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { SharedService } from 'src/app/api/shared.service';
import { SharedDevicesService } from 'src/app/shared-devices.service';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit {

  result : any;
  constructor(
    private _sharedDevicesService : SharedDevicesService
    ) { }
  @Input() document: any[] = [];
  @Input() sharedDevices: any; 
  
  sharedContracts : any;
  
  ngOnInit(): void {
    
    this.sharedContracts = this.document[0].contratosCompartilhado;
    this._sharedDevicesService.getSharedDevicesObservable(this.sharedDevices)
      .subscribe((data : any) => {
        // console.log('aqke', data)
      })
    
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  hasSharedContract(){
    return this.sharedContracts.length > 0 ? true : false;
  }

}
