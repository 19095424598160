import { ThrowStmt } from '@angular/compiler';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractService } from 'src/app/api/contract.service';
import { NotificationToastService } from 'src/app/notification-toast.service';

@Component({
  selector: 'app-contract-modal-share',
  templateUrl: './contract-modal-share.component.html',
  styleUrls: ['./contract-modal-share.component.scss']
})

export class ContractModalShareComponent implements OnInit {
  permission : string = '';
  hasChange : boolean;

  permissionText : string = "Permissão"

  userAcess : any;
  _isLoading : boolean = false;
  _isLoadingShare : boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ContractModalShareComponent>,
  private _contractService : ContractService,
  private _toast : NotificationToastService
  ) { 

    this.hasChange = false;
  }

  loginFormControl = new FormControl('', [
  
  ]);
  
  ngOnInit(): void {
    this._isLoading = true;
    this._contractService.getUsersAcessContract(this.data.contract).subscribe((data : any) => {
      this.userAcess = data as any;
      this._isLoading = false;
    })
  }

  ngOnDestroy() : void {
    this.close();
  }

  shareContract(loginUser: string){

    this._isLoadingShare = true;

    this._contractService.shareEntireContract(this.data.contract, loginUser, this.permission).subscribe(data => {
      let result = data as any;
      this.clearInputValues();
      this._toast.showSuccess(result.mensagem, 'Sucesso !!' );
      this.ngOnInit()
      this.hasChange = true
    }, err => {
      this._toast.showError('Erro ao compartilhar o contrato', err);
      console.log(err);
    })
  }

  unshareContract(loginUser: string){
    

    this._contractService.unshareEntireContract(this.data.contract, loginUser).subscribe(data => {
      let result = data as any;
      this._toast.showSuccess('Sucesso !!', result.mensagem);
      this.ngOnInit()
      this.hasChange = true
    }, err => {
      console.log('erro' , err);
      this._toast.showError('Erro ao compartilhar o contrato', err);
    })
  }

  close(){
    console.log('close', this.hasChange)
    this.dialogRef.close();
    if(this.hasChange){ //se foi chamada API atualiza os dados do documento.
      window.location.reload();
    }
  }

  clearInputValues(){
    this.permission = '';
    this.permissionText = 'Permissão'
    this.loginFormControl.setValue('');
  }

  getRemoveButtonTooltip(name : string) : string{
    return "Clique aqui para remover acessos usuário \n\n" + name;
  }

  setRead(){
    this.permissionText = 'Leitura'
    this.permission = 'r';
  }

  setWrite(){
    this.permissionText = 'Escrita'
    this.permission = 'w';

  }


}
