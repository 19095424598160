import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment.prod';
@Injectable()
export class MessagingService {
    

    currentMessage = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging,
        private http: HttpClient,) {
        if(navigator && navigator.serviceWorker){
            navigator.serviceWorker.addEventListener('message', this.onReceiveMessage.bind(this));
            navigator.serviceWorker.addEventListener('foreground', this.receiveMessage.bind(this));
        }
        
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                //_messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
                
            }
        )

        //navigator.serviceWorker.addEventListener('message', this.onReceiveMessage.bind(this))
    }

    onReceiveMessage(event : any){
        console.log('onReceiveMessage ', event)

        if(event.data != null){
            var type = event.data.firebaseMessagingType;
            if(type == 'push-msg-received'){
                var firebaseMessagingData = event.data.firebaseMessagingData,
                from = firebaseMessagingData.from,
                payload = firebaseMessagingData.notification

                if(from === environment.firebase.messagingSenderId){
                    console.log('message received from firebase ', payload)
                }
            }
        }
    };

    requestPermission(){
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                console.log(token);
                var result = token as any;
                sessionStorage.setItem("fcm", result );
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                console.log("foreground message . ", payload);
                this.currentMessage.next(payload);
            })
    }

    subscribeToTopic(topic: string) {
        var token = sessionStorage.getItem('fcm'); 
        fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
            method: 'POST',
            headers: new Headers({
                //Authorization: `key=${FCM_SERVER_KEY}`
                Authorization: 'key=AAAA3RQQ_tA:APA91bH65xHHqkvjMu874h7VOA7u-vqcRa0VvHErCTGkKpxFZNt-jXepJOPSTlfLnWCq1mXc_-zMEtrU2hdDL6uNHSxJO8uzDHG_9NVErznMlt7naD5N8Bj_vMdQL09pmPGOG8HZFkvG'
            })
        })
            .then((response) => {
                if (response.status < 200 || response.status >= 400) {
                    console.log(response.status, response);
                }
                console.log(`"${topic}" is subscribed`);
            })
            .catch((error) => {
                console.error(error.result);
            });
        return true;

    }

    subscribeToTopic2(topic: string) {
        var token = sessionStorage.getItem('fcm'); 
        console.log(token)
        return this.http.post(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, null, {
            headers: new HttpHeaders({
                // 'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE, OPTIONS',
                // "Access-Control-Allow-Credentials": "true",
                // 'Access-Control-Allow-Origin': '*',
                // 'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Authorization': 'key=AAAA3RQQ_tA:APA91bH65xHHqkvjMu874h7VOA7u-vqcRa0VvHErCTGkKpxFZNt-jXepJOPSTlfLnWCq1mXc_-zMEtrU2hdDL6uNHSxJO8uzDHG_9NVErznMlt7naD5N8Bj_vMdQL09pmPGOG8HZFkvG'
            }),
        });
    } 
        
}
