<ng-container *ngIf="!hasSharedContract() else elseBlock">
    Não há compartilhamentos.
</ng-container>



<!-- <div *ngFor="let sharedD of sharedDevices">
    <div *ngFor="let shared of sharedD">
        <mat-card class="shared-card">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon>shared</mat-icon>
                </div>
                <mat-card-title>{{shared.chipId}}</mat-card-title>
                <mat-card-subtitle>{{shared.contratoShared}}</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>

            </mat-card-content>

            <mat-card-actions>
      
            </mat-card-actions>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>

            </mat-card-footer>
        </mat-card>
    </div>
</div> -->


<ng-template #elseBlock>
    <app-devices [documentOfShared]="document" [sharedDevices]=sharedDevices></app-devices>
        <!-- <div *ngFor="let shared of sharedContracts">
        <mat-card class="shared-card">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon>shared</mat-icon>
                </div>
                <mat-card-title>{{shared.contratoShared}}</mat-card-title>
                <mat-card-subtitle>{{shared.contratoShared}}</mat-card-subtitle>
            </mat-card-header>
    
            <mat-card-content>

            </mat-card-content>

            <mat-card-actions>
                
            </mat-card-actions>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
    
            </mat-card-footer>
        </mat-card>
    </div> -->

</ng-template>