import { SimpleChanges, ɵConsole } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Util from 'src/app/util';
import { DeviceModalPrincipalComponent } from '../device-modal-principal/device-modal-principal.component';
import { DeviceModalComponent } from '../device-modal/device-modal.component';

@Component({
  selector: 'app-grid-devices',
  templateUrl: './grid-devices.component.html',
  styleUrls: ['./grid-devices.component.scss']
})
export class GridDevicesComponent implements OnInit {

  @Input() document: any[] = [];
  @Input() devices: any[] = [];
  
  columns = 10;
  rowWidth = 1;
  rowHeight = 1;

  sharedDevicesVar : any;

  options: FormGroup;
  colorControl = new FormControl('primary');
  fontSizeControl = new FormControl(10, Validators.min(4));
  devicesModifiable: any[] = [];

  constructor(fb: FormBuilder, public dialog: MatDialog,) {
    this.options = fb.group({
      color: this.colorControl,
      fontSize: this.fontSizeControl,
    });
  }

  ngOnInit(): void {
    
    
    
  }
  ngDoCheck() {
    // if (this.devices) {
    //   this.devices.sort((device: any) => {
    //     return device.isAcionado && !device.isWillTopic ? -1 : 1;
    //   })
    // }
    
    // console.log('ngDoCheck')
  }

  ngAfterViewInit(){
    // console.log('ngAfterViewInit')
  }

  ngAfterViewChecked(){
    // console.log('ngAfterViewChecked')
  }
  ngAfterContentInit(){
    // console.log("ngAfterContentInit")
  }

  ngAfterContentChecked(){
    // console.log("ngAfterContentChecked")  
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ngOnChanges - Grid Devices' , changes)
    this.devices = changes.devices.currentValue;
    // this.sharedDevices = changes.sharedDevices.currentValue;

    if (this.devices) {
      this.devices.sort((device: any) => {
        return device.isAcionado && !device.isWillTopic ? -1 : 1;
      })
    }
  }

  getColumns() {
    return this.fontSizeControl.value;
  }

  getBackgroundColor(device: any) {
    if (device.isWillTopic) {
      return "#bcbcbc";
    } else {
      return device.isAcionado ? "red" : "#152f55";
    }
  }

  click(device: any) {
    
    this.dialog.open(DeviceModalComponent, {
      data: {device: device, document: this.document},
      height: '90%',
      width: '90%',
    })
  }

  getTooltipClass(device: any) {
    if (device.isWillTopic) {
      return "custom-tooltip-will"
    } else {
      return device.isAcionado ? "custom-tooltip-alert" : "custom-tooltip";
    }
  }

  getImageIcon(device: any) {
    return Util.getImageIcon(device);
  }

  getTileClass(device: any){

    if(device.isWillTopic){
      return '';
    }

    if(device.isAcionado){
      return 'animate__animated animate__pulse animate__infinite';
    }
    return '';
  }

}
