<div class="col-lg-10" style="margin-top: 5%;">
    <div class="row-md-6" style="text-align: center;">
        <img style="margin-bottom: 5%;" matTooltip="Sempre IoT"
            class="animate__animated animate__fadeInDownBig animate__slower 3s" src="./assets/imgs/sempreiot-logo.png">

    </div>
    <div class="row-md-6" style="text-align: center;">
        <mat-form-field class="mat-form-field-login animate__animated animate__fadeInLeft" appearance="fill">
            <mat-label>Entre com seu login</mat-label>
            <input #inputLogin matInput type="text" [(ngModel)]="value">
            <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>


    <div class="row-md-6" style="text-align: center;">
        <mat-form-field class="mat-form-field-password animate__animated animate__fadeInLeft" appearance="fill">
            <mat-label>Entre com sua senha</mat-label>
            <input (keydown.enter)="logOn(inputLogin.value, inputPassword.value)" #inputPassword matInput
                [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon class="">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
    </div>


    <div class="row-md-6" style="text-align: center;">
        <mat-spinner style="margin:0 auto;" *ngIf="loading"></mat-spinner>

        <ng-container *ngIf="!loading">
            <a routerLink="/home">
                <button mat-raised-button class="mat-raised-button-login" type="submit"
                    (click)="logOn(inputLogin.value, inputPassword.value)"><b>ENTRAR</b></button></a>
        </ng-container>
    </div>


    <!-- <iframe width="718" height="480" src="https://www.youtube.com/embed/PTFYkVeh7Z0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    <ngb-alert class="custom-alert">
        <div class="col">
            <div class="row" style="color: red; margin-bottom: 1%;">
                <b style="text-align: center;"
                    class="animate__animated animate__flash animate__infinite animate__slow">ATENÇÃO !</b>
            </div>
            <div class="row"
                style="color: white; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                <li>Nós não armazenamos nenhuma informação nos <b>COOKIES</b></li>
                <li>Não compartilhamos ou vendemos informações e ou dados sensíveis de clientes.</li>
                <li>Toda comunicação entre cliente e dispositivo é segura e criptografada.</li>

            </div>


        </div>
    </ngb-alert>
</div>