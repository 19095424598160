import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrincipalComponent } from './principal/principal.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { BodyComponent } from './body/body.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list'
import { ComponentsModule } from '../components/components.module';
import {MatMenuModule} from '@angular/material/menu';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderEndComponent } from './header-end/header-end.component'
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ComponentsLogsModule } from '../components-logs/components-logs.module';



@NgModule({
  declarations: [
    PrincipalComponent,
    HeaderComponent,
    MenuComponent,
    BodyComponent,
    HeaderEndComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ComponentsLogsModule,

    NgbModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    FontAwesomeModule,
    MatTabsModule,
    MatProgressSpinnerModule,

    

  ],
  exports:[
    PrincipalComponent,
    HeaderComponent,
    MenuComponent,
    BodyComponent,
    
  ]

})
export class HomeModule { }
