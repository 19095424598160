import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogsService } from 'src/app/api/logs.service';

@Component({
  selector: 'app-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrls: ['./device-modal.component.scss'],
})
export class DeviceModalComponent implements OnInit {
  _selectedIndex : any = 0;
  _logs : any;
  _isLoading : boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private router: Router,
    private _logService: LogsService
    ) { }  
  
  _hasChange : boolean = false;  
  ngOnInit(): void {
    this.dialog.afterAllClosed.subscribe(() => {
      if(this._hasChange){
        this.router.navigate(['/home']).then(() => window.location.reload());
      }
    })
  }

  hasChange(hasChange: boolean){
    //console.log('hasChange ', hasChange)
    this._hasChange = hasChange;
    
  }

  onTabChange(event : any){
    
    this._selectedIndex = event.index;

    if(this._selectedIndex == 4){
      this._isLoading = true;
      this._logs = [];
      //console.log('refresh de logs')
      this._logService.getLastOcurrenciesOfDevice(this.data.device.device.chipId).subscribe(
        (data) => {

          //console.log(data);
          this._isLoading = false;
          this._logs = data as any;
        }, err => {console.log(err); this._isLoading = false;}
      )
    }
  }

}
