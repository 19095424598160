import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { LinksService } from 'src/app/api/links.service';
import { NotificationToastService } from 'src/app/notification-toast.service';
import Util from 'src/app/util';

@Component({
  selector: 'app-device-modal-links',
  templateUrl: './device-modal-links.component.html',
  styleUrls: ['./device-modal-links.component.scss']
})
export class DeviceModalLinksComponent implements OnInit {
  @Input() data: any;
  @Output() newItemEvent = new EventEmitter<any>();
  constructor(
    private _linksService: LinksService,
    private _toast : NotificationToastService,
    private _mqttService: MqttService
    ) { }

  allDevices: any[] = [];
  linkableList: string[] = [];
  _isLoading : boolean = false;

  ngOnInit(): void {
    this.selectDevices();
    // console.log('all devices: ', this.allDevices)
    this.linkableList = Util.linkableList(this.data.device.device.tipo)
  }

  isDisabled(){
    var permission : any = '';
    this.data.device.device.grupo.filter((group : any) => {
      if(group.loginCelular == this.data.document[0].login){
        permission = group.permissao 
      }
    })

    
    return permission == 'r' ? true : false;  
  }

  selectDevices() {
    this.data.document.forEach((doc: any) => {
      for (let i = 0; i < doc.devices.length; i++) {
        this.allDevices.push(doc.devices[i],);
      }
    })
  }

  isEligibileToLink(type: string): boolean {

    return this.linkableList.includes(type) ? true : false;
  }

  getImageIcon(type: any) {
    return Util.getStaticImageIcon(type);
  }

  getTypeText(type: any){
    return Util.getTypeText(type);
  }

  link(selectedDevices: any){
    this._isLoading = true;
    let errorList: string[] = [];

    selectedDevices.forEach((element: any, index: any) => {
      
      
      this._linksService.linkDevices(this.data.device.device.chipId, element.value.chipId)
        
        .subscribe((data: any) => {
          this._isLoading = true; 
          let result = data as any;
          this.restarDevice(element.value)
          
          this.newItemEvent.emit(true);
          this._toast.showSuccess("Dispositivo(s) vinculado(s) com sucesso ! Aguarde o restart dos dispositivos para surtir efeito.", element.value.descricao);
          this.restarDevice(this.data.device.device)
          this._isLoading = false;
        }, 
        err => {          
          console.log(err);
          errorList = ["Erro ao tentar vincular com o dispositivo " + element.value.descricao];
          this._toast.showError("Erro ao tentar vincular com o dispositivo.", element.value.descricao);
          this._isLoading = false;
        }
        )
    });

    

  }

  restarDevice(device: any){
    this._mqttService.publish(Util.removeWildCardFromTopic(device.mqtt_topic_subscribe_restart), 'r', {retain: false} ).subscribe(
      ()=> {
        this._toast.showInfoDevice("Restart para vínculos", "O dispositivo será reiniciado para surtir efeitos de vínculos.")
        Util.removeWildCardFromTopic(device.mqtt_topic_subscribe_restart), 'r'
      },
      (error) => {
        console.log('[ATENÇÃO] - ERRO ', error);
      }
    );
  }
}
