import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DevicesComponent } from './devices/devices.component';
import {MatCardModule} from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRippleModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTreeModule} from '@angular/material/tree';
import {MatDividerModule} from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';

import { DevicepipePipe } from './devices/devicepipe.pipe';
import { DeviceModalComponent } from './device-modal/device-modal.component';
import { DeviceLinksBottomSheetComponent } from './device-links-bottom-sheet/device-links-bottom-sheet.component';
import { ContractsComponent } from './contracts/contracts.component';
import { GridDevicesComponent } from './grid-devices/grid-devices.component';
import { GridDevicesModalComponent } from './grid-devices-modal/grid-devices-modal.component';
import { DeviceAddModalComponent } from './device-add-modal/device-add-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceRemoveModalComponent } from './device-remove-modal/device-remove-modal.component';
import { SharedComponent } from './shared/shared.component';
import { AppRoutingModule } from '../app-routing.module';
import { DevicePanelComponent } from './device-panel/device-panel.component';
import { DeviceModalPrincipalComponent } from './device-modal-principal/device-modal-principal.component';
import { DeviceModalAccessComponent } from './device-modal-access/device-modal-access.component';
import { DeviceModalLinksComponent } from './device-modal-links/device-modal-links.component';
import { DeviceModalShareComponent } from './device-modal-share/device-modal-share.component';
import { DeviceModalLogsComponent } from './device-modal-logs/device-modal-logs.component';
import { ConsoleComponent } from './console/console.component';
import { OccurrencesComponent } from './occurrences/occurrences.component';
import { ContractModalShareComponent } from './contract-modal-share/contract-modal-share.component';
import { ContractModalUnshareComponent } from './contract-modal-unshare/contract-modal-unshare.component';
import { DeviceModalLinkedsComponent } from './device-modal-linkeds/device-modal-linkeds.component';


@NgModule({
  declarations: [
    PageNotFoundComponent,
    DevicesComponent,
    DevicepipePipe,
    DeviceModalComponent,
    DeviceLinksBottomSheetComponent,
    ContractsComponent,
    GridDevicesComponent,
    GridDevicesModalComponent,
    DeviceAddModalComponent,
    DeviceRemoveModalComponent,
    SharedComponent,
    DevicePanelComponent,
    DeviceModalPrincipalComponent,
    DeviceModalAccessComponent,
    DeviceModalLinksComponent,
    DeviceModalShareComponent,
    DeviceModalLogsComponent,
    ConsoleComponent,
    OccurrencesComponent,
    ContractModalShareComponent,
    ContractModalUnshareComponent,
    DeviceModalLinkedsComponent,
    
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatRippleModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatGridListModule,
    MatTooltipModule,
    NgbModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    AppRoutingModule,
    MatDividerModule,
    MatTabsModule,
    MatListModule,
    MatProgressBarModule,
    MatMenuModule,
    
    
  ],
  exports:[
    PageNotFoundComponent,
    DevicesComponent,
    ContractsComponent,
    GridDevicesComponent,
    SharedComponent,
    ConsoleComponent,
    OccurrencesComponent
  ]
})
export class ComponentsModule { }
