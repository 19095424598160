
<div *ngIf="data.device.device.vinculos.length == 0">
  <p>Esse dispositivo não possui vínculos.</p>
</div>

<mat-selection-list #vinculos [multiple]="true">
    <mat-list-option [disabled]="isDisabled()" *ngFor="let vinculo of data.device.device.vinculos" [value]="vinculo">
      <div class="row">
        <div [matTooltip]="getChipIdText(vinculo.id_vinculado)" class="col col-lg-10" style="text-align: start; margin-top: 1%;" >
          {{vinculo.descricao}} - {{vinculo.id_vinculado}}
        </div>
      </div>

    </mat-list-option>
    <mat-divider *ngIf="data.device.device.vinculos.length > 0"></mat-divider>
</mat-selection-list>



<div *ngIf="vinculos.selectedOptions.selected.length > 0" style="margin-top: 3%;">
  <p>Selecionados: </p>
  <ng-container *ngFor="let selected of vinculos.selectedOptions.selected">
    <div class="col col-lg-12">
      {{selected.value.descricao}}
      <mat-divider></mat-divider>
    </div>
  </ng-container>
</div>

  <div *ngIf="vinculos.selectedOptions.selected.length > 0" style="text-align: center; margin-top: 3%;">
    <button class="btn btn-outline-danger" (click)="unlink(vinculos.selectedOptions.selected)" >Remover Vínculos</button>
  </div>
  