import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpParams, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of, } from 'rxjs';
import { catchError, tap, } from 'rxjs/operators';

//produção
const apiReference = '/api/'


@Injectable({
  providedIn: 'root'
})
export class DocumentService {
    
  
  private _documentsBehavior = new BehaviorSubject<any[]>([]);
  private dataStore: { todos: any[] } = { todos: [] };

  
  someProperty : any;
  session : any;
  constructor(private http: HttpClient,) { }
  
  getDocument(login : string) : Observable<any>{
    this.session = sessionStorage.getItem('session');
    let sessionJson = JSON.parse(this.session);
    //console.log('loginn ', login)
    let loginDecoded = atob(sessionJson.l);
    //console.log('loginDecoded ',  loginDecoded)
    
    let httpOptions : any = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + atob(sessionJson.token)}),                      
      };
    
    
    return this.http.get(apiReference + 'documento/lista/contrato/' + loginDecoded, httpOptions);
  }

  // getDocumentObserver(){
  //   this.getDocument("36315113885").subscribe(data => {
  //     this.dataStore.todos = data;
  //     this._documentsBehavior.next(Object.assign({}, this.dataStore).todos);
  //   }, error => console.log('Could not load todos.', error));
  // }

  // get(){
  //   return this.dataStore.todos;
  // }
  
}
