<h3>Vínculos</h3>
<ng-container *ngIf="device.vinculos?.length else elseBlock">
    <ng-container *ngFor="let link of device.vinculos; index as i">
        <div class="row">

            <button (click)="deleteLink(link)">{{link.descricao}}</button>
        </div>
    </ng-container>
</ng-container>
<ng-template #elseBlock>
    Este dispositivo não possui vínculos.
</ng-template>

