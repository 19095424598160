<div class="row">
    <mat-slide-toggle *ngIf="isActionable(device.device.tipo)" style="margin: 20px;"
                [disabled]="device.isWillTopic" (change)="publish(device.device, !device.isAcionado)"
                [checked]=device.isAcionado></mat-slide-toggle>
    <div class="col">
        {{getDeviceState(device)}}
    </div>
</div>
<div class="row">    
    <div class="col">
        <mat-form-field class="w-75 p-3" >
            <mat-label>Nome do dispositivo</mat-label>
            <input matInput maxLength="30" placeholder="Dê o nome que preferir para o dispositivo"
                [formControl]="deviceDescriptionFormControl" value="deviceDescription" [(ngModel)]="deviceDescription">
            <button *ngIf="deviceDescriptionFormControl.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="deviceDescriptionFormControl.setValue('')">
                <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="deviceDescriptionFormControl.hasError('required') ">
                <p>Dê um nome ao seu dispositivo.</p>
            </mat-error>
            <mat-hint>{{deviceDescription.length || 0}}/30</mat-hint>
        </mat-form-field>
        
        <button *ngIf="deviceDescriptionFormControl.value != deviceDescriptionOld && deviceDescriptionFormControl.value != ''" 
                mat-icon-button 
                matTooltip="Salvar" 
                aria-label="Salvar"
                (click)="updateDescription(device.device.chipId, deviceDescriptionFormControl.value)">
            <mat-icon style="color: green;">done</mat-icon>
        </button>  
    </div>   

    <mat-divider style="margin-top: 3%; margin-bottom: 3%;"></mat-divider>

    
    <div class="col">
        <h5>Internet de Contingência</h5>
        <h6 style="color: red;">ATENÇÃO ! Só configure se realmente tiver uma internet de contingência disponível!</h6>
    </div>
    <div class="col">
        <mat-form-field>
            <mat-label>SSID</mat-label>
            <input matInput maxLength="30" placeholder="SSID da rede"
                [formControl]="ssidFormControl" value="ssid" [(ngModel)]="ssid">
            <button *ngIf="ssidFormControl.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="ssidFormControl.setValue('')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div class="col">
        <mat-form-field>
            <mat-label>Senha</mat-label>
            <input matInput maxLength="30" placeholder="Senha da rede"
                [formControl]="passwordFormControl" value="password" [(ngModel)]="password">
            <button *ngIf="passwordFormControl.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="passwordFormControl.setValue('')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <button *ngIf="ssidFormControl.value != oldSsid || 
                       ssidFormControl.value == '' && 
                       passwordFormControl.value == '' || 
                       passwordFormControl.value != oldPassword " 
                mat-icon-button 
                matTooltip="Salvar" 
                aria-label="Salvar"
                (click)="save()">
            <mat-icon style="color: green;">done</mat-icon>
        </button>  
    </div>
</div>



<div class="row" style="margin-top: 5%;" >
    <button  class="btn btn-outline-info" matTooltip="Reinicializa o dispositivo" [disabled]="isDisabled()" (click)="restartDevice(true)" >Restart</button>
    <button class="btn btn-outline-danger" matTooltip="Reset de fábrica !" [disabled]="isDisabled()" (click)="resetDevice()">Reset</button>
</div>