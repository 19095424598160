import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevicePanelComponent } from './components/device-panel/device-panel.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PrincipalComponent } from './home/principal/principal.component';
import { LoginScreenComponent } from './login/login-screen/login-screen.component';

const routes: Routes = [
  {
    path: 'home',
    component: PrincipalComponent,
    data: {title: 'Home'}
  },
  {
    path: 'login',
    component: LoginScreenComponent,
    data: {title: 'Login'}
  },
  {
    path: 'device-panel',
    component: DevicePanelComponent,
     
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**', 
    component: PageNotFoundComponent
  }     
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
