import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Observer } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContractService } from 'src/app/api/contract.service';
import { DocumentService } from 'src/app/api/document.service';
import { MessagingService } from 'src/app/messaging.service';
import { MosquittoService } from 'src/app/mqtt/mosquitto.service';
import { NotificationToastService } from 'src/app/notification-toast.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {
  events: any[] = [];
  private deviceId: string = "#";
  subscription: Subscription | undefined;
  payload : any;

  loggedIn : any;

  document : any;

  login : any;

  shared : any; 
  sharedDevices: any = [];
  sharedDevicesObservable : Observable<any> | any;
  session : any;
  

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      this.login = params.login
    });
    this._getDocument();
    this.sharedDevicesObservable = this.getSharedDevicesObservable();
    
    //console.log('looogeed', this.loggedIn)
  }

  ngOnChange(change : SimpleChange){
    console.log('onchange Principal', change);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
  }

  constructor(
    private readonly eventMqtt: MosquittoService,
    private apiDocument : DocumentService,
    private route: ActivatedRoute,
    private _contractService : ContractService,
    private _mqttService: MqttService,
    private toastNotification : NotificationToastService,
    private fcm : MessagingService
  ) {

    
  }

  private subscribeToTopic(documents : any) {
    
    for(let i = 0; i < documents.length; i++){
      for(let j = 0; j < documents[i].devices.length; j++){
        this.subscription = this.eventMqtt.subscribeTopic(documents[i].devices[j].chipId)
      .subscribe((data: IMqttMessage) => {
          //let item = JSON.parse(data.payload.toString());
          let item = data.payload.toString();
          this.payload = data.payload.toString();
          this.events.push(item);
      });

      let topic :string = documents[i].devices[j].mqtt_topic_publish_alarm[0].mqtt_topic_publish_alarm;

      
      topic = topic.replace("/", "-").replace("/", "-").replace("/", "-").replace("/", "-").replace("/", "-").replace("+", "")
      console.log(topic)
      
       this.fcm.subscribeToTopic(topic.replace("/", "-").replace("+", ""))
      
      // this.fcm.subscribeToTopic2(topic.replace("/", "-").replace("+", "")).subscribe(data => console.log(data))
      }
      
    }    
    
  }

  public replaceAll(str : string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  private _getDocument() : any{
    console.log('_getDocument')
    this.getSharedDevicesObservable();
    return this.apiDocument.getDocument(this.login)
    .subscribe(
      (data) =>{
        
        this.subscribeToTopic(data);
        this.document = data as any;
        //console.log('>>>>>>>>>>>>>>>>>' , this.document)
        this.shared = this.document[0]?.contratosCompartilhado;
        if(this.shared.length > 0){
          this._getSharedDevices(this.shared);
        }
        
        return data;
      }
    );
  }

  private _getSharedDevices(shared: any){
    
    let loginUser: string;

    if(this.login == undefined || this.login == null || this.login.length == 0){
      this.session = sessionStorage.getItem('session');
      let sessionJson = JSON.parse(this.session);
      loginUser = atob(sessionJson.l);
    } else {
      loginUser = atob(this.login);
    }

    
    
    let shareds: any[] = [];
    
    shared.forEach((value : any) => {
      this._contractService.getDocumentOfSharedContract(value.contratoShared, loginUser).subscribe((data => {
        let document = data as any;
        document.forEach((doc : any) => {
          doc.devices.filter((device : any) => device.grupo.filter((grupo : any) => {
            if(grupo.loginCelular == loginUser){
              this._subOfShared(device.chipId)
              this.sharedDevices.push([device])
              return true;
            } else {
              return false;
            }
          }));

          //console.log(this.sharedDevices)
        });
      }))  
    });
    
  }

  
 public getSharedDevicesObservable(): any {
  const sharedDevicesObservableConst = new Observable(observer => {
         setTimeout(() => {
             observer.next(this.sharedDevices);
         }, 1000);
  });

  return sharedDevicesObservableConst;
}

  private _subOfShared(chipId : string){
    this.eventMqtt.subscribeTopic(chipId)
      .subscribe((data: IMqttMessage) => {
          let item = data.payload.toString();
          this.events.push(item);
      });
    this.fcm.subscribeToTopic2(chipId)
  }
  

}
