<mat-sidenav-container class="sidenav-container">
  
  <!-- <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar style="color: #152f55">Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item style="color: #152f55" routerLink="/contratos" routerLinkActive="active">
        <mat-icon style="color: #54b3d2">blur_on</mat-icon> <b>Contratos</b>
      </a>
      <a mat-list-item style="color: #152f55" routerLink="/usuarios" routerLinkActive="active">
        <mat-icon style="color: #54b3d2">supervised_user_circle</mat-icon> <b>Usuários</b>
      </a>
      <a mat-list-item style="color: #152f55" routerLink="/dispositivos" routerLinkActive="active">
        <mat-icon style="color: #54b3d2">touch_app</mat-icon> <b>Dispositivos</b>
      </a>
      <a mat-list-item style="color: #152f55" routerLink="/logs" routerLinkActive="active">
        <mat-icon style="color: #54b3d2">info</mat-icon> <b>Logs</b>
      </a>
      <a mat-list-item style="color: #152f55" routerLink="/aplicativo" routerLinkActive="active">
        <mat-icon style="color: #54b3d2">smartphone</mat-icon> <b>Aplicativo</b>
      </a>
    </mat-nav-list>
  </mat-sidenav> -->

  <mat-sidenav-content>
    <app-header [document]="document"></app-header>
  </mat-sidenav-content>

  
</mat-sidenav-container>