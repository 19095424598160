import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { MosquittoService } from 'src/app/mqtt/mosquitto.service';
@Injectable({
  providedIn: 'root'
})
export class MqttControllerService {

  events: any[] = [];
  private deviceId: string = "test";
  subscription: Subscription | undefined;
  payload: any;
  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(
    private readonly eventMqtt: MosquittoService,
    private _mqttService: MqttService
  ) { }

  //  subscribeToTopic() {
  //   this.subscription = this.eventMqtt.subscribeTopic(this.deviceId)
  //     .subscribe((data: IMqttMessage) => {
  //       console.log('SUbscrbe ' + data.payload.toString());
  //       //let item = JSON.parse(data.payload.toString());
  //       let item = data.payload.toString();
  //       this.payload = data.payload.toString();
  //       this.events.push(item);
  //     });
  // }

  publishToTopic(topic : string, payload : string, isRetained : boolean){
    
    this._mqttService.publish(topic, payload, {retain: isRetained} ).subscribe(
      ()=> {
        // console.log(topic, payload);
      },
      (error) => {
        console.log('[ATENÇÃO] - ERRO ', error);
      }
    );
  }
}
