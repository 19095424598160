
import { Input } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { Constants } from 'src/app/constants';
import { SharedDevicesService } from 'src/app/shared-devices.service';
import Util from 'src/app/util';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleComponent implements OnInit {


  @Input() document: any[] = [];
  @Input() sharedDevices: any = []; 
  
  constructor(public _mqttService: MqttService,
    private _sharedDevicesService : SharedDevicesService) { }
  dataList : any[] = [];
  allDevices: any = [];
  
  devicesDescriptionList : any = [];

  ngOnInit(): void {
    this._sharedDevicesService.getSharedDevicesObservable(this.sharedDevices).subscribe((data : any) => {
      this._joinAllDevices(data);

      this._mqttService.messages.subscribe(data => {
        // console.log(data.topic, data.payload.toString())
  
        if(!data.retain){
          if(data.topic.includes(Constants.TOPIC_STATE)){
            this._handleState(data);
          }
    
          if(data.topic.includes(Constants.TOPIC_START)){
            this._handleStart(data);
          }
    
          if(data.topic.includes(Constants.TOPIC_WILL)){
            this._handleWill(data);
          }
    
          if(data.topic.includes(Constants.TOPIC_BATTERY)){
            this._handleBattery(data)
          }
    
          if(data.topic.includes(Constants.TOPIC_RRSI)){
            this._handleRrsi(data);
          }
    
          if(data.topic.includes(Constants.TOPIC_RESTART)){
            this._handleRestart(data);
          }
    
          if(data.topic.includes(Constants.TOPIC_RESET)){
            this._handleReset(data);
          }
        }
        
  
      
      })
    })

    
    
  }
  private _pushDeviceList(device: any) {
    this.devicesDescriptionList.push({
      chipId: device.chipId,
      description: device.descricao,
      type: device.tipo
    });
  }

  _joinAllDevices(sharedDevices : any[]){
    this.document.forEach((document : any) => {
      document.devices.forEach((device: any) => {
        this.allDevices.push(device)
        this._pushDeviceList(device)
      });
    })
    
    if(this.sharedDevices.length > 0){
      this.sharedDevices[0].forEach((device: any) => {
        this.allDevices.push(device)
        this._pushDeviceList(device)
      });
    }
    
  }

  _handleState(data : any){
    var chipId = Util.getChipIdFromTopic(data.topic) 
    var deviceDescription = this._extractDescriptionFromDevice(chipId);
    this.dataList.push({
      payload: data.payload.toString(), 
      description: 'Rede WiFi',
      deviceDescription: deviceDescription,
      icon: 'wifi_password',
      animationClass: 'animate__animated animate__flash animate__infinite animate__slow',
      matTooltip: 'Rede WiFi conectada',
      color: 'green',
      date: this.getFormattedDate()
    });
  }

  _handleStart(data : any){
    var chipId = Util.getChipIdFromTopic(data.topic) 
    var deviceDescription = this._extractDescriptionFromDevice(chipId);
    this.dataList.push({
      payload: data.payload.toString() == 'Booting' ? 'Dispositivo iniciado' : data.payload.toString(), 
      description: '',
      deviceDescription: deviceDescription,
      icon: data.payload.toString() == 'Booting' ? 'check_circle' : 'public',
      animationClass: 'animate__animated animate__flash animate__infinite animate__slow',
      matTooltip: data.payload.toString() == 'Booting' ?  'Dispositivo iniciado . . .' : 'IP Local',
      color: 'green',
      date: this.getFormattedDate()
    });
  }

  _handleWill(data : any){
    var chipId = Util.getChipIdFromTopic(data.topic) 
    var deviceDescription = this._extractDescriptionFromDevice(chipId);
    this.dataList.push({
      payload: data.payload.toString() == '1' ? 'OFFLINE' : 'ONLINE', 
      description: '',
      deviceDescription: deviceDescription,
      icon: data.payload.toString() == '1' ? 'wifi_off' : 'check',
      animationClass: 'animate__animated animate__flash animate__infinite animate__slow',
      matTooltip: data.payload.toString() == '1' ? 'OFFLINE' : 'ONLINE',
      color: data.payload.toString() == '1' ? 'red' : 'green',
      date: this.getFormattedDate()
    });
  }

  _handleBattery(data : any){
    var color;

    if (data.payload <= 2600 && data.payload < 2800) {
      color = "red"
    } else if (data.payload >= 2800 && data.payload <= 3000) {
      color = 'yellow'
    } else {
      color = 'green'
    }

  
    var chipId = Util.getChipIdFromTopic(data.topic) 
    var deviceDescription = this._extractDescriptionFromDevice(chipId);
    this.dataList.push({
      payload: data.payload.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), 
      description: '',
      deviceDescription: deviceDescription,
      icon: 'battery_std',
      animationClass: 'animate__animated animate__flash animate__infinite animate__slow',
      matTooltip: 'Nível da bateria em volts.',
      color: color,
      date: this.getFormattedDate()
    });
  }

  _handleRrsi(data : any){
    var color;

    if (data.payload < -75 && data.payload <= 85) {
      color = "yellow"
    }else if (data.payload < -90) {
      color = "red"
    } else {
      color = "green"
    }

    
    

    var chipId = Util.getChipIdFromTopic(data.topic) 
    var deviceDescription = this._extractDescriptionFromDevice(chipId);
    this.dataList.push({
      payload: data.payload.toString(), 
      description: '',
      deviceDescription: deviceDescription,
      icon: 'network_check',
      animationClass: '',
      matTooltip: 'Sinal de WiFi da rede',
      color: color,
      date: this.getFormattedDate()
    });
  }

  _handleRestart(data : any){
    var chipId = Util.getChipIdFromTopic(data.topic) 
    var deviceDescription = this._extractDescriptionFromDevice(chipId);
    this.dataList.push({
      payload: 'Restart enviado ao dispositivo', 
      description: 'RESTART',
      deviceDescription: deviceDescription,
      icon: 'refresh',
      animationClass: '',
      matTooltip: 'Restart no dispositivo...',
      color: 'green',
      date: this.getFormattedDate()
    });
  }

  _handleReset(data : any){
    var chipId = Util.getChipIdFromTopic(data.topic) 
    var deviceDescription = this._extractDescriptionFromDevice(chipId);
    this.dataList.push({
      payload: 'Reset enviado ao dispositivo',
      description: 'RESET',
      deviceDescription: deviceDescription,
      icon: 'restart_alt',
      animationClass: '',
      matTooltip: 'Reset de fábrica no dispositivo',
      color: 'green',
      date: this.getFormattedDate()
    });
  }

  private _extractDescriptionFromDevice(chipId : string) : string {
    return this.devicesDescriptionList.filter((device : any) => device.chipId == chipId)[0].description
  }

  public getMatTooltip(matTooltip : string) : string {
    return matTooltip;
  }

  public getFormattedDate() : string{
    var date = new Date();
    return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  }
}
