<mat-form-field appearance="fill" [color]="colorControl.value" style="margin: 10px;">
    <mat-label>Quantidade de Colunas</mat-label>
    <input matInput type="number" placeholder="Ex. 12" [formControl]="fontSizeControl" min="4">
    <span matSuffix>Colunas</span>
    <mat-error *ngIf="fontSizeControl.invalid">Mínimo de 4 colunas</mat-error>
</mat-form-field>

<mat-grid-list [cols]="getColumns()" rowHeight="1:1">

    <mat-grid-tile [class]="getTileClass(device)" matRipple matTooltip={{device.device.descricao}} [matTooltipClass]="getTooltipClass(device) "
        (click)="click(device)" [style.background]="getBackgroundColor(device)" *ngFor="let device of devices">
        <div>
            <img class="img-responsive" style="width: 70px; height: 60px;" [src]="getImageIcon(device)">
            <div class="row" *ngIf=device.isWillTopic>
                <div class="col-md-12">
                    <mat-icon class="animate__animated animate__flash animate__infinite animate__slow" style="color: red; position: absolute;">wifi_off</mat-icon>
                </div>
            </div>
        </div>
    </mat-grid-tile>

</mat-grid-list>



