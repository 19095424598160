import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDevicesService {

  constructor() { }

  public getSharedDevicesObservable(sharedDevices: any): any {
    const devicesSharedObservable = new Observable(observer => {
      setTimeout(() => {
        observer.next(sharedDevices);
      }, 1000);
    });

    return devicesSharedObservable;
  }

}
