import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IMqttMessage, MqttService } from "ngx-mqtt";;
@Injectable({
  providedIn: 'root'
})
export class MosquittoService {

  private endpoint: string;

  constructor(
    private _mqttService: MqttService,
  ) {
    this.endpoint = 'test';
  }

  subscribeTopic(deviceId: string): Observable<IMqttMessage> {
    //let topicName = `/${this.endpoint}/${deviceId}`;   
    let topicName = `${deviceId}/#`;   
    //console.log('Tópico Subscribe: ' + topicName );  
    return this._mqttService.observe(topicName);
  }


}
