import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import Util from '../util';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private http: HttpClient,) { }


  getDocumentOfSharedContract(contract: string, login: string){
    return this.http.get(Constants.API_ENDPOINT + 'documento/lista/chave/contrato/' + contract + '&' + login , {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });
  }

  shareEntireContract(contract: string, userLogin: string, permission: string){
    return this.http.post(Constants.API_ENDPOINT + 'grupos/compartilhar/contrato/' + contract + '&' + userLogin + '&' + permission , null, {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });
  
  }

  unshareEntireContract(contract: string, userLogin: string){

    return this.http.delete(Constants.API_ENDPOINT + 'grupos/descompartilhar/contrato/' + contract + '&' + userLogin , {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });
  }

  getUsersAcessContract(contract : string){
    return this.http.get(Constants.API_ENDPOINT + 'grupos/compartilhados/usuarios/' + contract  , {
      headers: new HttpHeaders({
                                'Access-Control-Allow-Methods' :'POST, GET, PUT, DELETE, OPTIONS',
                                "Access-Control-Allow-Credentials": "true",
                                'Access-Control-Allow-Origin': '*',
                                'Acess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', 
                                'Authorization': 'Bearer ' + Util.getToken()}),                      
      });

  }
}
