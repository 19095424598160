import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-device-links-bottom-sheet',
  templateUrl: './device-links-bottom-sheet.component.html',
  styleUrls: ['./device-links-bottom-sheet.component.scss']
})
export class DeviceLinksBottomSheetComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<DeviceLinksBottomSheetComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public device: any ) { }

  ngOnInit(): void {
  }
  
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  deleteLink(link : any){
    
  }

}
