import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MqttService } from 'ngx-mqtt';
import { DeviceService } from 'src/app/api/device.service';
import { Constants } from 'src/app/constants';
import { MqttControllerService } from 'src/app/mqtt/mqtt-controller.service';
import { NotificationToastService } from 'src/app/notification-toast.service';
import Util from 'src/app/util';

@Component({
  selector: 'app-device-modal-principal',
  templateUrl: './device-modal-principal.component.html',
  styleUrls: ['./device-modal-principal.component.scss']
})
export class DeviceModalPrincipalComponent implements OnInit {

  @Input() data: any;
  @Output() newItemEvent = new EventEmitter<any>();
  constructor(private _deviceService: DeviceService,
    private _toast: NotificationToastService,
    private _mqttService: MqttService,
    private _mqttControllerService: MqttControllerService
    ) {
   }

  
  deviceDescriptionFormControl = new FormControl('', [
    Validators.required
  ]);

  ssidFormControl = new FormControl('', [
    
  ]);

  passwordFormControl = new FormControl('', [
    
  ]);
  
  document: any;
  device: any;

  deviceDescription : string = '';
  deviceDescriptionOld : string = '';

  ssid : string = '';
  oldSsid: string = '';

  password : string = '';
  oldPassword : string = '';

  hasChange : boolean = false;

  ngOnInit(): void {
    
    this.document = this.data.document;
    this.device = this.data.device;

    //Internet de contingência
    this.ssid = this.device.device.contingency_network_ssid;
    this.oldSsid = this.device.device.contingency_network_ssid;

    this.password =  this.device.device.contingency_network_password;
    this.oldPassword = this.device.device.contingency_network_password;

    this.ssidFormControl.setValue(this.ssid);
    this.passwordFormControl.setValue(this.password);



    //Descrição do dispositivo
    this.deviceDescription = this.device.device.descricao;
    this.deviceDescriptionOld = this.device.device.descricao;
    this.deviceDescriptionFormControl.setValue(this.deviceDescription);
  }

  updateDescription(chipId : string, description: string){
    var body = {
      chipId: chipId,
      descricao: description
    }

    //api não retorna JSON por isso será tratado o retorno no err.
    this._deviceService.updateDescription(body).subscribe(data => {
    }, 
    err => {
      if(err.status == 200){
        console.log('sucesso')
        this.deviceDescriptionOld = this.deviceDescriptionFormControl.value;
        this.newItemEvent.emit(true);
      } else {
        console.log('erro')
      }
    })
  }

  save(){

    if(this.ssidFormControl.value == '' && this.passwordFormControl.value != '' ){
      this._toast.showInfo('Atenção', 'Digite o nome da SSID (nome da rede WiFi)')  
    } else {
      var body = {
        "chipId" : this.device.device.chipId,
        "ssid" : this.ssidFormControl.value,
        "senha": this.passwordFormControl.value
      }
    //api não retorna JSON por isso será tratado o retorno no err.
      this._deviceService.saveContingencyInternet(body).subscribe(() => {
      }, err => {
        if(err.status == 200){
          console.log('sucesso')
          this.deviceDescriptionOld = this.deviceDescriptionFormControl.value;
          this.oldSsid = this.ssidFormControl.value;
          this.oldPassword = this.passwordFormControl.value;
          this.newItemEvent.emit(true);
          this.restartDevice(false);
        } else {
          this._toast.showError('Erro !', 'Ao tentar gravar internet de contingência.')
          console.log(err);
        }
      })
    }
    

  }

  restartDevice(alert: boolean){
    var topic = Util.removeWildCardFromTopic(this.device.device.mqtt_topic_subscribe_restart);
          
    this._mqttService.publish(topic, 'r', {retain: false} ).subscribe(
      ()=> {
        //console.log(topic, 'r');
      },
      (error) => {
        console.log('[ATENÇÃO] - ERRO ', error);
      }
    );

    if(alert) this._toast.showWarning("Restart de dispositivo", "Acompanhar o dispositivo para sua reinicialização.")
  }

  resetDevice(){
    var topic = Util.removeWildCardFromTopic(this.device.device.mqtt_topic_publish_reset);
          
    this._mqttService.publish(topic, 'z', {retain: false} ).subscribe(
      ()=> {
        //console.log(topic, 'z');
      },
      (error) => {
        console.log('[ATENÇÃO] - ERRO ', error);
      }
    );

    this._toast.showWarning("Reset de dispositivo", "O dispositivo irá apagar as informações contidas e inicializar configuração de fábrica.")
  }

  isDisabled(){
    return this.device.isWillTopic ? true : false;
  }

  getDeviceState(device: any){
    return Util.getDeviceState(device);
  }

  isActionable(type : string){
    return Constants.ACTIONABLES.includes(type)
  }

  publish(device: any, isAcionado: boolean) {
    // console.log(device);
    // console.log(device.mqtt_topic_publish_alarm[0].mqtt_topic_publish_alarm)
    let topic = device.mqtt_topic_publish_alarm[0].mqtt_topic_publish_alarm.replace("+", this.document[0].login + ' - ' + this.document[0].nome + ' ' + this.document[0].sobrenome)
    // console.log(topic)
    let payload = isAcionado ? "1" : "0";
    this._mqttControllerService.publishToTopic(topic, payload, false)

  }
  
}
