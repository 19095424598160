<div class="container-principal">
    <mat-toolbar>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="icon-menu"
            aria-label="Example icon-button with menu icon">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">


            <button mat-menu-item (click)="handleHome()">
                <mat-icon>home</mat-icon>
                <span>Home</span>
            </button>
            <button mat-menu-item>
                <mat-icon>face</mat-icon>
                <span>Perfil</span>
            </button>
            <button mat-menu-item (click)=logout()>
                <mat-icon style="color: red;">logout</mat-icon>
                <span>Sair</span>
            </button>

        </mat-menu>
        
        <span *ngIf="document">{{document[0].nome}} {{document[0].sobrenome}}</span>
        
        <span class="example-spacer"></span>

        <div class="container d-flex justify-content-end" style="margin-right: 0%;">
            <app-header-end></app-header-end>
        </div>
    </mat-toolbar>
</div>