<!-- <ng-container *ngFor="let doc of document;"> -->
<div class="col-md-12 col-md-offset-5">

  <div class="row">
    <div class="col-10">
      <mat-form-field class="device-form-field" appearance="fill" autofilled="true">
        <mat-label>Pesquisar dispositivo por nome</mat-label>
        <input matInput type="text" [(ngModel)]="value" [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

    </div>

    <div *ngIf="documentOfShared.length == 0" class="col-2" style="margin-top: 10px;">
      <button (click)="openAddDeviceModal()" mat-fab matTooltip="Clique aqui para adicionar dispositivo"
        style="background-color: green;" aria-label="Clique aqui para adicionar dispositivo">
        <mat-icon>add</mat-icon>
      </button>

    </div>
  </div>



  <ng-container *ngFor="let device of devices | devicepipe:value ">
    <!-- <div class="example-ripple-container mat-elevation-z4"
     matRipple
     [matRippleCentered]="false"
     [matRippleDisabled]="false"
     [matRippleUnbounded]="false"
     > -->

    <mat-card style="margin: 20px;" [style.border]="getStyleBorder(device)">
      <mat-card-header>
        <div mat-card-avatar [style.background-color]="getStyleBackgroundColor(device)"
          [style.background-image]="'url(' + '../' + getImageIcon(device) + ')'"
          style="background-size: cover; width: 60px; height: 60px;  background-position: center;"></div>
        <mat-card-title>{{device.device.descricao}}</mat-card-title>
        <mat-card-subtitle>{{device.device.chipId}}</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>

        <div class="container">
          <div class="row">

            <div class="col col-md-2">
              <mat-slide-toggle *ngIf="isActionable(device.device.tipo)" style="margin: 20px;"
                [disabled]="device.isWillTopic" (change)="publish(device.device, !device.isAcionado)"
                [checked]=device.isAcionado></mat-slide-toggle>
            </div>

            <div class="col" style="margin-top: 2%;">
              STATUS: <b>{{getDeviceState(device)}}</b>
              
            </div>

            <div class="col col-sm-1" style="text-align: -webkit-right;">
              <button *ngIf="!device.isShared" mat-fab style="background-color: #152f55; "
                (click)="openModalShareDevice(device.device.chipId, device.device.descricao)"
                matTooltip="Clique aqui para compartilhar esse dispositivo."
                aria-label="Clique aqui para compartilhar esse dispositivo">
                <mat-icon>share</mat-icon>
              </button>
            </div>

            <div class="col col-sm-1" style="text-align: -webkit-right;">
              <button *ngIf="!device.isShared" mat-fab style="background-color: red; "
                (click)="openModalRemoveDevice(device.device.chipId, device.device.descricao)"
                matTooltip="Clique aqui para excluir esse dispositivo."
                aria-label="Clique aqui para excluir esse dispositivo.">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            
          </div>
        </div>




      </mat-card-content>

      <mat-card-actions>
        <div class="col-md-5 justify-content-between">

          <button [class]="getClassAnimationDevice(device)" mat-stroked-button
            [color]="getColor(device)" (click)="openDeviceDialog(device)">Abrir Dispositivo</button>


          <button mat-icon-button (click)="openBottomSheet(device.device)" matTooltip="Ver dispositivos vinculados"
            aria-label="Ver dispositivos vinculados">
            <mat-icon>schema</mat-icon>
          </button>
          <button mat-icon-button [style.color]="getColorStyleContingencyInternet(device.device)" [matTooltip]="getContingencyInternetToolTip(device.device)"
            aria-label="Internet de contingência 4G ou WiFi">
            <mat-icon>4g_plus_mobiledata</mat-icon>
          </button>

          <button mat-icon-button *ngIf="device.isWillTopic">
            <mat-icon  matTooltip="Dispositivo OFFLINE" class="animate__animated animate__flash animate__infinite animate__slow"
            style="color: red;">wifi_off</mat-icon>
          </button>
          
          <button mat-icon-button *ngIf="!device.isWillTopic && !device.isAcionado">
            <mat-icon  style="color: green;" matTooltip="ONLINE" class="animate__animated animate__heartBeat animate__infinite">task_alt</mat-icon>    
          </button>
          
          <button mat-icon-button *ngIf="device.isAcionado">
            <mat-icon  style="color: red;" matTooltip="Dispositivo em alarme" class="animate__animated animate__pulse animate__infinite">notifications_active</mat-icon>
          </button>
          
        </div>


      </mat-card-actions>

      <mat-card-footer>

      </mat-card-footer>
    </mat-card>
    <!-- </div> -->



  </ng-container>
</div>

<!-- </ng-container> -->