<div class="row">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="0">
        <mat-tab label="Dispositivo">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 20px;" class="tab-icon-devices">developer_board</mat-icon>
                <b>{{data.device.device.chipId}}-{{data.device.device.tipo}}</b>
              </ng-template>
              <app-device-modal-principal (newItemEvent)="hasChange($event)" [data]=data></app-device-modal-principal>
        </mat-tab>

        <mat-tab label="Acessos">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 20px;" class="tab-icon-devices">groups</mat-icon>
                <b>Acessos</b>
              </ng-template>
              <app-device-modal-access (newItemEvent)="hasChange($event)" [data]=data></app-device-modal-access>
        </mat-tab>
        
        <mat-tab label="Vincular">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 20px;" class="tab-icon-devices">multiple_stop</mat-icon>
                <b>Vincular</b>
              </ng-template>
              <app-device-modal-links (newItemEvent)="hasChange($event)" [data]=data></app-device-modal-links>
        </mat-tab>
        

        <mat-tab label="Vínculos">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 20px;" class="tab-icon-devices">schema</mat-icon>
                <b>Vínculos</b>
              </ng-template>
              <app-device-modal-linkeds (newItemEvent)="hasChange($event)" [data]=data></app-device-modal-linkeds>
        </mat-tab>

        <!-- <mat-tab label="Compartilhar">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 20px;" class="tab-icon-devices">share</mat-icon>
                <b>Compartilhar</b>
              </ng-template>
 
        </mat-tab>        -->
        
        <mat-tab label="Logs">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 20px;" class="tab-icon-devices">share</mat-icon>
                <b>Logs</b>
              </ng-template>
              <app-device-modal-logs [data]=data [logs]=_logs [isLoading]="_isLoading"></app-device-modal-logs>
        </mat-tab>       
    </mat-tab-group>
</div>


