import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContractModalShareComponent } from '../contract-modal-share/contract-modal-share.component';
import { ContractModalUnshareComponent } from '../contract-modal-unshare/contract-modal-unshare.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }
  @Input() document: any[] = [];

  ngOnInit(): void {
    
  }

  openContractModalShare(contract : string, contractDescription : string){
    this.dialog.open(ContractModalShareComponent, {data: {contract: contract, contractDescription: contractDescription},
      height: '90%',
      width: '90%', 
      // 
    })
  }

  openContractModalUnshare(contract: string, contractDescription: string){
    this.dialog.open(ContractModalUnshareComponent, {data: {contract: contract, contractDescription: contractDescription}, 
      // height: '90%',
      // width: '90%', 
      panelClass: ['md:w-3/5', 'w-full'],
    })
  }
}
