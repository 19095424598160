
<div *ngIf="data.device.device.grupo.length == 0">
  Esse dispositivo não possui acessos.
</div>
<mat-selection-list #user [multiple]="false">
    <mat-list-option *ngFor="let user of data.device.device.grupo" [value]="user">
      <div class="row">
        <div class="col col-sm-1" style="text-align: end; ">
          <button mat-icon-button><mat-icon style="color: red;" [matTooltip]="getRemoveButtonTooltip(user.nome)">close</mat-icon></button>
        </div>
        <div class="col col-lg-8" style="text-align: start; margin-top: 1%;" >
          {{user.nome}}
        </div>
        <div *ngFor="let icon of getAccessIcon(user.permissao)" class="col col-sm-1"  >
          
          <mat-icon  style="color: #152f55;">{{icon}}</mat-icon>
        </div>
      </div>
      
    </mat-list-option>
  </mat-selection-list>

  <div style="margin-top: 5%;" *ngIf="user.selectedOptions.selected[0] && !data.device.isShared">
    Selecionado: {{user.selectedOptions.selected[0]?.value.nome}}
    <button mat-button [matMenuTriggerFor]="menu">{{getAccessTypeText(user.selectedOptions.selected[0]?.value.permissao)}} <mat-icon>arrow_drop_down</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="setPermission(user.selectedOptions.selected[0]?.value, 'r', user.selectedOptions.selected[0]?.value.loginCelular) ? user.selectedOptions.selected[0].value.permissao = 'r' : 'r' ">Leitura
        <mat-icon *ngIf="user.selectedOptions.selected[0]?.value.permissao == 'r'" style="margin-top: 1%; text-align: end; color: green;">done</mat-icon>
      </button>
      <button mat-menu-item (click)="setPermission(user.selectedOptions.selected[0]?.value, 'w', user.selectedOptions.selected[0]?.value.loginCelular) ? user.selectedOptions.selected[0].value.permissao = 'w' : 'w'">Escrita
        <mat-icon *ngIf="user.selectedOptions.selected[0]?.value.permissao == 'w'" style="margin-top: 1%; text-align: end; color: green;">done</mat-icon>
      </button>
    </mat-menu>
  </div>

  <div *ngIf="_isLoading" style="text-align: center;">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>


  