import { HttpErrorResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Directive, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeviceService } from 'src/app/api/device.service';
import { NotificationToastService } from 'src/app/notification-toast.service';

@Component({
  selector: 'app-device-add-modal',
  templateUrl: './device-add-modal.component.html',
  styleUrls: ['./device-add-modal.component.scss']
})
export class DeviceAddModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public document: any,
    public dialogRef: MatDialogRef<DeviceAddModalComponent>,
    private deviceService : DeviceService,
    public dialog: MatDialog,
    public router: Router,
    private _toast : NotificationToastService) { }
  
  buttonDisabled : boolean = false;
  message: string = '';
  isLoading : boolean = false;
  hasError : boolean = false;
  hasChange: boolean = false; //verifica se chamou a API alguma vez
  contract: string = '';

  chipIdFormControl = new FormControl('', [
    Validators.required,
  ]);

  typeFormControl = new FormControl('', [
    Validators.required,
    Validators.min(1),
    Validators.max(15,)
  ]);

  ngOnInit(): void {
    this.contract = this.selectContract();
    
    this.dialog.afterAllClosed.subscribe(() => {
      if(this.hasChange){
        this.router.navigate(['/home']).then(() => window.location.reload());
      }
    })
  }

  addDevice(chipId: string, type : string, ){
    this.isLoading = true;
    this.message = '';
    this.hasError = false;
    this.deviceService.addDevice(chipId, this.contract, type)
    .subscribe((data) => {
      let result = data as any;
      this.message = result.mensagem;
      this.isLoading = false;
      this.hasChange = true;
      this.chipIdFormControl.reset();
      this.typeFormControl.reset();
      this._toast.showSuccess(this.message, "Sucesso !")
    },
    err => {
      console.log(err.error)
      this.message = err.error.mensagem
      this.isLoading = false;
      this.hasError = true;
      this._toast.showInfo(err.error.mensagem, "Erro !")
    });  
    
  }

  //seleciona o primeiro contrato, se tiver cheio pega o segundo
  selectContract(){
    let contract = '';

    for(let i = 0; i < this.document.length; i++ ){
      if(this.document[i].devices.length < this.document[i].quantidadeMaximaDispositivos){
       contract = this.document[i].contrato;
      }
      break;
    }
    
    return contract;
  }

  close(){
    this.dialogRef.close();
    if(this.hasChange){ //se foi chamada API atualiza os dados do documento.
      window.location.reload();
    }
  }

  getAlertType(){
    return this.hasError ? "danger" : "success"
  }
  

}

@Directive({
  selector: '[validChipId]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidChipIdDirective, multi: true}]
})
export class ValidChipIdDirective implements Validator {
  @Input('validChipId') validChipId = '';

  validate(control: AbstractControl): ValidationErrors | null {
    return this.validChipId ? chipIdValidator(this.validChipId)(control)
                              : null;
  }
}

/** A hero's name can't match the given regular expression */
export function chipIdValidator(chipId: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = chipId.includes('-');
    return forbidden ? {forbiddenName: {value: control.value}} : null;
    
  };
}