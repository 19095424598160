import { Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { LogsService } from 'src/app/api/logs.service';
import { Constants } from 'src/app/constants';
import { SharedDevicesService } from 'src/app/shared-devices.service';
import Util from 'src/app/util';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  @Input() document: any[] = [];
  @Input() sharedDevices: any[] = []; 
  
  constructor(
    private _logsService: LogsService,
    private _sharedDevicesService : SharedDevicesService
    ) { }

  findFormControl = new FormControl('', []);
  findValue = '';
  iconTest = 'battery_full'

  filters : string[] = [];
  allDevices: any[] = [];
  allLogs: any;
  _isLoading: boolean = true;
  // pageSize: any = 15;
  allLogsPage : any;
  allDevicesShared: any[] = [];
  allDevicesSharedWithNames: any[] = [];

  ngOnInit(): void {
    this._isLoading = true;
    
    this.selectDevices();
    let body = this.mountBody();
    this._sharedDevicesService.getSharedDevicesObservable(this.sharedDevices).subscribe( (data : any) => {
      this.sharedDevices = data as any;
      // console.log('devices shared para logs ', this.allDevicesShared = this.joinSharedDevices(this.sharedDevices));
      if(this.allDevicesShared.length > 0){
        this.allDevicesShared.forEach((device : any) => {
          
          body.push(device);
        })
      }
      //console.log('body antes, ', body);
      if(this.allDevices.length > 0){
        this._logsService.getLastOcurrencies(body).subscribe(data => {
          // console.log(data);
          let result = data as any;
          this.allLogs = result;
          this.allLogsPage = result;
          this._isLoading = false;
          this.filterAllLogsPage()
        });
      } else {
        this._isLoading = false;
      }
      
    })
    
    
    
    


    
    
  }

  refresh(){
    this.findFormControl.setValue('')
    this.ngOnInit()
  }


  selectDevices() {
    this.document.forEach((doc: any) => {
      for (let i = 0; i < doc.devices.length; i++) {
        this.allDevices.push(doc.devices[i],);
      }
    })
    
  }

  joinSharedDevices(sharedDevices : any) {
    let devicesSharedBody: any = [];
    sharedDevices.forEach((sharedDevicesDev : any) => {
      sharedDevicesDev.forEach((device : any) => {
        devicesSharedBody.push({chipId: device.chipId, tipo: device.tipo}); //para montar o body da request
        this.allDevicesSharedWithNames.push(device);
      });
    });

    return devicesSharedBody;
  }
  mountBody() {
    let body: { chipId: any; tipo: any; }[] = [];
    this.allDevices.forEach(device => {
      body.push({ chipId: device.chipId, tipo: device.tipo });
    });

    return body;
  }

  getDeviceNameGivenChipId(chipId: string): string {
    return Util.getDeviceNameGivenChipId(this.allDevices, this.allDevicesSharedWithNames, chipId);
  }

  filterAllLogsPage(){
     //this.allLogsPage = this.allLogs?.slice(0, 1000);
    this.allLogsPage = this.allLogs
    
  }

  getLog(log: any){
    //console.log(log);
  }


  selectUserName(textTopic : string){
    return Util.getNameUserFromTopicSend(textTopic);
  }

  applyFilter(event: any){
    this.findFormControl.setValue('')
    // console.log(event);
    this.filterAllLogsPage();
    this.filters = [];
    if(event.value == 'todos'){
      this.filters = [];
    }
     else {
      this.filters.push(event.value);
      // console.log('filter aplied ', this.filters)
      this.allLogsPage = this.allLogsPage.filter((log: any) => {
        return log.tipoTopico.includes(this.filters[0])
      })

      // console.log(this.allLogsPage)
     }
  }

  getIconLog(log: any) : string{
    return Util.getIconByLog(log.tipoTopico, log.payload);
  }  

  getIconColor(log: any){
    return Util.getIconColorLog(log);
  }

  getAnimatedClass(log: any){
    return Util.getAnimatedClassLog(log);
  }

  getStyleBorder(log : any){
    return Util.getStyleBorderLog(log);
  }

  extractMessageFromPayload(log : any){
    return Util.extractMessageFromPayloadLog(log);
  }

  onChange(findText: string){
    // this._isLoading = true;
    this.findValue = findText.toLowerCase().trimEnd();
    
    // console.log(this.allLogsPage)

    if(this.allLogsPage?.length == 0){
      this.filterAllLogsPage()
    }

    if(this.findValue.length == 0){
       //this.allLogsPage = this.allLogs?.slice(0, 1000);
      this.allLogsPage = this.allLogs
    }
    if(this.findValue.length > 0){
      this.allLogsPage = this.allLogsPage.filter((log: any) => {
        // console.log(log)
        
        if(this.getDeviceNameGivenChipId(log.chipId).toLocaleLowerCase().includes(this.findValue)){
          
          console.log('filter ', this.filters)

          if(this.filters[0] == 'todos')
          this.filterAllLogsPage()
          
          return log;
          } else {
            

            if(log.tipoTopico.includes(this.filters[0])){
              if(this.findValue.length == 0 ){
                return log;
              } else {
                if(this.getDeviceNameGivenChipId(log.chipId).toLocaleLowerCase().includes(this.findValue)){
                  return log;
                }
                
              }
              
              
            }
        }
      })
    }

    // this._isLoading = false;
  }
}
