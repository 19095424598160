import { Output } from '@angular/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import Util from 'src/app/util';

@Component({
  selector: 'app-device-modal-logs',
  templateUrl: './device-modal-logs.component.html',
  styleUrls: ['./device-modal-logs.component.scss']
})
export class DeviceModalLogsComponent implements OnInit {

  @Input() logs: any;
  @Input() data: any;
  @Input() isLoading: boolean = false;
  @Output() newItemEvent = new EventEmitter<any>();
  constructor() { }
    ngOnInit() {
      
    }

    getDeviceNameGivenChipId(chipId: string){
      return Util.getDeviceNameGivenChipId([this.data.device.device], null, this.data.device.device.chipId )
    }

    selectUserName(textTopic : string){
      return Util.getNameUserFromTopicSend(textTopic);
    }

    getIconLog(log: any) : string{
      return Util.getIconByLog(log.tipoTopico, log.payload);
    }  


    //TODO jogar no UTIL
    getIconColor(log: any){
      return Util.getIconColorLog(log);
    }

    getAnimatedClass(log: any){
      return Util.getAnimatedClassLog(log);
    }

    extractMessageFromPayload(log : any){
      return Util.extractMessageFromPayloadLog(log);
    }
}
