import { LogicalProjectPath } from "@angular/compiler-cli/src/ngtsc/file_system";
import { Constants } from "./constants";

const apiReference = '/api/'
export default class Util {

  static getToken(): string {
    let session: any = sessionStorage.getItem('session');
    let bearerToken = JSON.parse(session);
    bearerToken.token = atob(bearerToken.token)
    // console.log(bearerToken.token)
    return bearerToken.token;
  }

  static getAnimatedClassLog(log: any) {
    switch (log.tipoTopico) {
      case Constants.WILL_TOPIC_TYPE:
        return "animate__animated animate__flash animate__infinite animate__slow"

      case Constants.RRSI_TOPIC_TYPE:
        return "animate__animated animate__tada animate__infinite animate__slow"

      case Constants.ALARM_TOPIC_TYPE:
        return "animate__animated animate__pulse animate__infinite animate__slow"

      case Constants.RESTART_TOPIC_TYPE:
        return "animate__animated animate__flip animate__infinite animate__slower 3s"

      case Constants.RESET_TOPIC_TYPE:
        return "animate__animated animate__flipInX animate__infinite animate__slower 3s"

      case Constants.BATTERY_TOPIC_TYPE:
        if (log.payload <= 2600 && log.payload < 2800) {
          return "animate__animated animate__bounce animate__infinite"
        } else {
          return '';
        }

    }

    return ''
  }
  static getIconColorLog(log: any) {
    switch (log.tipoTopico) {
      case Constants.WILL_TOPIC_TYPE:
        return "red"

      case Constants.RRSI_TOPIC_TYPE:
        if (log.payload < -75 && log.payload <= 85) {
          return "yellow"
        }
        if (log.payload < -90) {
          return "red"
        }
        return "green"

      case Constants.BATTERY_TOPIC_TYPE:
        if (log.payload <= 2600 && log.payload < 2800) {
          return "red"
        }

        if (log.payload >= 2800 && log.payload <= 3000) {
          return 'yellow'
        }

        return 'green';

      case Constants.ALARM_TOPIC_TYPE:
        return "red"
      
      case Constants.CONFIRMATION_TOPIC_TYPE:
        return "green"
    }

    return "black"
  }

  static getTokenLog(): string {
    let session: any = sessionStorage.getItem('session');
    let bearerToken = JSON.parse(session);
    bearerToken.tokenLog = atob(bearerToken.tokenLog)
    // console.log(bearerToken.token)
    return bearerToken.tokenLog;
  }

  static getChipIdFromTopic(topic: string): string {
    return topic.substring(0, topic.indexOf("/"));
  }

  static getDeviceState(device: any): string {
    if (device.isWillTopic) {
      return "OFFLINE";
    }

    if (device.isAcionado) {
      return "ACIONADO";
    } else {
      return "DESLIGADO";
    }
  }

  static getDeviceNameGivenChipId(allDevices: any, allSharedDevices: any, chipId: string): string {
    var result = allDevices.filter((device: any) => device.chipId == chipId)[0]?.descricao;
    if (result == null || result == undefined) {
      return allSharedDevices.filter((device: any) => device.chipId == chipId)[0]?.descricao;
    }
    return result;
  }

  static getStyleBorderLog(log: any) {

    switch (log.tipoTopico) {
      case Constants.ALARM_TOPIC_TYPE:
        return log.payload == "1" ? '1px solid rgba(255, 0, 0, 0.4)' : ''

      case Constants.CONFIRMATION_TOPIC_TYPE:
        return '1px solid rgba(0, 255, 0, 0.4)'

      default:
        return '';

    }
  }

  static extractMessageFromPayloadLog(log: any) {
    switch (log.tipoTopico) {
      case Constants.ALARM_TOPIC_TYPE:
        return log.payload == '1' ? 'Dispositivo ativado ou em alarme' : 'Dispositivo desativado'
        

      case Constants.CONFIRMATION_TOPIC_TYPE:
        return log.payload == '1' ? 'Recebimento de alarme confirmado pelo dispositivo' : 'Recebimento de desativação confirmado pelo dispositivo'

      case Constants.RESTART_TOPIC_TYPE:
        return 'Restart no dispositivo';

      case Constants.RESET_TOPIC_TYPE:
        return 'Reset no dispositivo';

      case Constants.BATTERY_TOPIC_TYPE:
        return log.payload;

      case Constants.RRSI_TOPIC_TYPE:
        return log.payload;

      case Constants.WILL_TOPIC_TYPE:
        return log.payload == '1' ? 'Dispositivo OFFLINE' : 'Dispositivo ONLINE'

      case Constants.BASEOFF_TOPIC_TYPE:
        return '';

      case Constants.LOOPFAIL_TOPIC_TYPE:
        return '';

      default:
        return '';
    }
  }

  static getIconByLog(typeLog: string, payload: string): string {

    switch (typeLog) {

      case Constants.ALARM_TOPIC_TYPE:
        return 'notifications_active'

      case Constants.RRSI_TOPIC_TYPE:
        return 'wifi'

      case Constants.BATTERY_TOPIC_TYPE:
        if (payload <= '2600' && payload < '2800') {
          return "battery_alert"
        }

        if (payload >= '2800' && payload <= '3000') {
          return 'battery_full'
        }
        return 'battery_saver'

      case Constants.WILL_TOPIC_TYPE:
        return 'wifi_off'

      case Constants.RESTART_TOPIC_TYPE:
        return 'refresh'

      case Constants.RESET_TOPIC_TYPE:
        return 'restart_alt'

      case Constants.START_TOPIC_TYPE:
        return 'info'

      case Constants.CONFIRMATION_TOPIC_TYPE:
        return 'check'

      case Constants.BASEOFF_TOPIC_TYPE:
        return ''

      case Constants.LOOPFAIL_TOPIC_TYPE:
        return ''

      case Constants.STATE_TOPIC_TYPE:
        return ''

      default:
        return ''
    }


  }


  static getNameUserFromTopicSend(topicSend: string) {
    var indexOf = topicSend.indexOf('-') + 1;
    return topicSend = topicSend.substring(indexOf);
  }
  static getImageIcon(device: any): string {

    switch (device.device.tipo) {
      case "1":
        return "../../assets/imgs/icones-dispositivos/ICONE-BOTAO-PNE-SEM-FUNDO.png";

      case "2":
        return "../../assets/imgs/icones-dispositivos/ICONE-LUMINOSO-PNE-SEM-FUNDO.png";

      case "3":
        return device.isAcionado ? "../../assets/imgs/icones-dispositivos/ICONE-MODULO-RELE-NA-NF-ON-SEM-FUNDO.png" : "../../assets/imgs/icones-dispositivos/ICONE-MODULO-RELE-NA-NF-OFF-PHOTOSHOP.png";

      case "4":
        return device.isAcionado ? "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-SOMENTE-PLAY-SEM-FUNDO.png" : "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-SOMENTE-PLAY-OFF-SEM-FUNDO.png";

      case "5":
        return device.isAcionado ? "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-PAUSE-SEM-FUNDO.png" : "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-PLAY-SEM-FUNDO.png";

      case "6":
        return "../../assets/imgs/icones-dispositivos/ICONE-SINALIZADOR-BRANCO-SEM-FUNDO.png";

      case "7":
        return "../../assets/imgs/icones-dispositivos/ICONE-BOTAO-CAMPAINHA-SEM-FUNDO.png";

      case "8":
        return "../../assets/imgs/icones-dispositivos/ICONE-LUMINOSO-CAMPAINHA-PNE-SEM-FUNDO.png";

      case "9":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-CONVENCIONAL-SEM-FUNDO.png";

      case "10":
        return "../../assets/imgs/icones-dispositivos/ICONE-LUMINOSO-INCENDIO-SEM-FUNDO.png";

      case "11":
        return device.isAcionado ? "../../assets/imgs/icones-dispositivos/ICONE-MODULO-RELE-PULSO-TEMPORIZADO-ON-SEM-FUNDO.png" : "../../assets/imgs/icones-dispositivos/ICONE-MODULO-RELE-PULSO-TEMPORIZADO-OFF-SEM-FUNDO.png";

      case "12":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-MONITOR-SIRENE-CONVENCIONAL-SEM-FUNDO.png";

      default:
        return "../../assets/imgs/icones-dispositivos/ICONE-SINALIZADOR-BRANCO-SEM-FUNDO.png";
    }


  }
  static getStaticImageIcon(type: string): string {
    switch (type) {
      case "1":
        return "../../assets/imgs/icones-dispositivos/ICONE-BOTAO-PNE-SEM-FUNDO.png";

      case "2":
        return "../../assets/imgs/icones-dispositivos/ICONE-LUMINOSO-PNE-SEM-FUNDO.png";

      case "3":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-RELE-NA-NF-ON-SEM-FUNDO.png";

      case "4":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-SOMENTE-PLAY-SEM-FUNDO.png";

      case "5":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-PAUSE-SEM-FUNDO.png";

      case "6":
        return "../../assets/imgs/icones-dispositivos/ICONE-SINALIZADOR-BRANCO-SEM-FUNDO.png";

      case "7":
        return "../../assets/imgs/icones-dispositivos/ICONE-BOTAO-CAMPAINHA-SEM-FUNDO.png";

      case "8":
        return "../../assets/imgs/icones-dispositivos/ICONE-LUMINOSO-CAMPAINHA-PNE-SEM-FUNDO.png";

      case "9":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-ENTRADA-CONVENCIONAL-SEM-FUNDO.png";

      case "10":
        return "../../assets/imgs/icones-dispositivos/ICONE-LUMINOSO-INCENDIO-SEM-FUNDO.png";

      case "11":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-RELE-PULSO-TEMPORIZADO-ON-SEM-FUNDO.png";

      case "12":
        return "../../assets/imgs/icones-dispositivos/ICONE-MODULO-MONITOR-SIRENE-CONVENCIONAL-SEM-FUNDO.png";

      default:
        return "../../assets/imgs/icones-dispositivos/ICONE-SINALIZADOR-BRANCO-SEM-FUNDO.png";
    }
  }


  static getTypeText(type: string) {
    switch (type) {
      case Constants.BOTAO_SANITARIO_PNE: //1
        return 'Botão Sanitário PNE';

      case Constants.ALERTA_SANITARIO_PNE: //2
        return 'Alerta Sanitário PNE';

      case Constants.MODULO_RELE_NA_NF: //3
        return 'Módulo Relé NA / NF';

      case Constants.ENTRADA_ATIVACAO_ON: //4
        return 'Entrada Ativação ON';

      case Constants.SINALIZADOR: //6
        return 'Sinalizador Comum';

      case Constants.BOTAO_CAMPAINHA: //7
        return 'Botão Campainha';
      case Constants.SINALIZADOR_CAMPAINHA: //8
        return 'Sinalizador Campainha';

      case Constants.ENTRADA_CONVENCIONAL: //9
        return 'Entrada Convencional';

      case Constants.SINALIZADOR_DE_INCENDIO: //10
        return 'Sinalizador de Incêndio';

      case Constants.RELE_PULSO: //11
        return 'Relé Pulso'

      case Constants.MONITOR_SAIDA_DE_SIRENE: //12
        return 'Monitor Saída de Sirene';
    }

    return 'Sem tipo definido'
  }

  static removeWildCardFromTopic(topic: string): string {
    return topic.replace('+', '');
  }

  static linkableList(type: string): string[] {
    switch (type) {
      case Constants.BOTAO_SANITARIO_PNE: //1
        return [Constants.ALERTA_SANITARIO_PNE, Constants.MODULO_RELE_NA_NF,];

      case Constants.ALERTA_SANITARIO_PNE: //2
        return [Constants.BOTAO_SANITARIO_PNE,];

      case Constants.MODULO_RELE_NA_NF: //3
        return [Constants.BOTAO_SANITARIO_PNE, Constants.RELE_PULSO, Constants.MODULO_RELE_NA_NF];

      case Constants.ENTRADA_ATIVACAO_ON: //4
        return [Constants.SINALIZADOR, Constants.SINALIZADOR_DE_INCENDIO, Constants.SINALIZADOR_CAMPAINHA, Constants.SINALIZADOR_DE_INCENDIO];

      case Constants.SINALIZADOR: //6
        return [Constants.ENTRADA_ATIVACAO_ON, Constants.ENTRADA_CONVENCIONAL,];

      case Constants.BOTAO_CAMPAINHA: //7
        return [];

      case Constants.SINALIZADOR_CAMPAINHA: //8
        return [];

      case Constants.ENTRADA_CONVENCIONAL: //9
        return [];

      case Constants.SINALIZADOR_DE_INCENDIO: //10
        return [];

      case Constants.RELE_PULSO: //11
        return [Constants.BOTAO_SANITARIO_PNE, Constants.BOTAO_CAMPAINHA];

      case Constants.MONITOR_SAIDA_DE_SIRENE: //12
        return [];


      default:
        break;
    }
    return [];
  }

}