import { Component, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { DocumentService } from 'src/app/api/document.service';
import { SharedDevicesService } from 'src/app/shared-devices.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {
  
  @Input() document : any[] = [];
  @Input() sharedDevices : any = [];
  devices : any;
  constructor(private documentService : DocumentService,
             private _sharedDevicesService : SharedDevicesService) {

   } 
  

  ngOnInit(): void {
    
  }

  // ngOnChanges(changes: any){
  //   console.log('body', changes)
  //   console.log('body2', changes.sharedDevices?.currentValue)
  //   this.sharedDevices = changes.sharedDevices?.currentValue;
  // }

  ngAfterContentInit() : void {
  
  }

  getUpdatedDevices(devices : any){
    this.devices = devices;
    this.sharedDevices = this.sharedDevices;
  }



}
